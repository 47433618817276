import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

import { Helmet } from "react-helmet";
import { ReactTypeformEmbed } from 'react-typeform-embed';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    marginTop: "8%",
    marginLeft: "8%",
    marginRight: "8%",
    marginBottom: "8%",
    flexGrow: 1,
  },
  heroRoot: {
    padding: 0,

    marginRight: "8%",

    flexGrow: 1,

    //backgroundColor: "#FDB78B", // Average color of the background image.
    width: `100%`,
    //height: 'calc(40vw)',
    [theme.breakpoints.up("xs")]: {
      height: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "499px",
    },

    margin: 0,
    marginBottom: 0,
  },
  heroImage: {
    position: "absolute",
    top: "50px",
  },
  intro_text: {
    fontFamily: [
      "Droid Serif",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: 400,
    fontSize: "40px",
    fontStyle: "italic",
    lineHeight: "40px",
    [theme.breakpoints.up("md")]: {
    marginBottom: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "60px",
    },
    color: theme.palette.primary.contrastText,
  },
  title_text: {
    fontFamily: [
      "Montserrat",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: 700,
    [theme.breakpoints.up("md")]: {
      fontSize: "75px",
      marginBottom: "50px",
      lineHeight: "75px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      marginBottom: "20px",
      lineHeight: "25px",
    },
    color: theme.palette.primary.contrastText,
  },
  titlecontainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "75px",
    },
  },
  bannerImage: {
    backgroundImage: `url('/img/ccsc-background.png')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#ffffff", // Average color of the background image.
    width: "100%",
    [theme.breakpoints.up("md")]: {
      height: "833px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "400px",
    },

    margin: 0,
    padding: 0,
  },
}));

export default function Registration() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Helmet>
        {
          //SEO goes here!
        }
        <meta charSet="utf-8" />
        <title>Register for Comberton Community Swimming Pool</title>
        <meta
          name="description"
          content="A member run swimming club in Comberton, Cambridge UK"
        />
      </Helmet>

      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="right"
        className={classes.bannerImage}
      >
          <ReactTypeformEmbed url="https://akendi1.typeform.com/to/sKPqqImb"/>
      </Grid>

    </React.Fragment>
  );
}
