import React from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray, getIn } from "formik";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import RowText from "../components/RowText";
import Paper from "@material-ui/core/Paper";
import SignupForm from "../components/signupForm";
import Header from "../components/Header";

import "date-fns";
import { mergeClasses } from "@material-ui/styles";

//excellent example of the arrays: https://gist.github.com/vicasas/410b11874267460484febbbe49bfbb1d

const useStyles = makeStyles((theme) => ({
  dataPicker: {
    marginTop: "16.5px",
  },
  button2: {
    marginTop: "0px",
    width: "20px",
  },
  root: {
    flexGrow: 1,
    marginLeft: "4%",
    marginRight: "4%",
    [theme.breakpoints.up("md")]: {
      marginTop: "138px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "95px",
    },
  },
  dataContainer: {
    flexGrow: 1,
  },
  gridRow: theme.pageMargins,
  paperLeft: {
    textAlign: "left",
    color: theme.palette.text.primary,
    width: "100%",
    height: "570px",
    margin: 'auto',
  },
  paperRight: {

    textAlign: "left",
    color: theme.palette.text.primary,
    width: "100%",
    height: "570px",
    margin: 'auto',
  },
  columnLeft: {
    padding: theme.spacing(0),
    width: "45%",
    height: "570px",
    flexGrow: 1,
  },
  columnRight: {
    padding: theme.spacing(0),
    width: "45%",
    height: "570px",
    flexGrow: 1,
  },
  coreColumn: {
    width: "100%",
    maxWidth: "400px",
    height: "570px",
    flexGrow: 1,
  },


 
}));

const debug = true;

export default function Signup() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >

      <Header />
      <SignupForm/>


    </Grid>
    </div>
  );
}
