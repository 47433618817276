import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import LoginOut from "../components/login_out";
import { makeStyles } from "@material-ui/core/styles";
import {
  IfFirebaseUnAuthed,
  IfFirebaseAuthed,
  FirebaseAuthConsumer,
} from "@react-firebase/auth";

import { Helmet } from "react-helmet";
import { db, auth } from "../elements/firebaseHelpers";
import Session from "../components/session";
import Typography from "@material-ui/core/Typography";
import FishItem from "../components/FishListItem";
import booking_info from "../svg/booking info.svg";
import booking_example from "../svg/booking example.svg";
import example_diagram from "../svg/example_diagram.svg";
import availability from "../svg/availability.svg";
import Paper from "@material-ui/core/Paper";

//https://react-firebase-js.com/docs/react-firebase-realtime-database/getting-started

const useStyles = makeStyles((theme) => ({
  root: theme.bodyBanner,
  gridRow: theme.pageMargins,
  circleStyle: {
    padding: 0,
    margin: "auto",
    marginBottom: "12px",
    display: "flex",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
    [theme.breakpoints.up("md")]: {
      width: 75,
      height: 75,
    },
    [theme.breakpoints.down("sm")]: {
      width: 75,
      height: 75,
    },
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  heroRoot: {
    padding: 0,

    marginRight: "8%",

    flexGrow: 1,

    //backgroundColor: "#FDB78B", // Average color of the background image.
    width: `100%`,
    //height: 'calc(40vw)',
    [theme.breakpoints.up("xs")]: {
      height: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "499px",
    },

    margin: 0,
    marginBottom: 0,
  },
  heroImage: {
    position: "absolute",
    top: "50px",
  },
  intro_text: {
    fontFamily: [
      "Droid Serif",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: 400,
    fontSize: "40px",
    fontStyle: "italic",
    lineHeight: "40px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "60px",
    },
    color: theme.palette.primary.contrastText,
  },
  title_text: {
    fontFamily: [
      "Montserrat",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: 700,
    [theme.breakpoints.up("md")]: {
      fontSize: "75px",
      marginBottom: "50px",
      lineHeight: "75px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      marginBottom: "20px",
      lineHeight: "25px",
    },
    color: theme.palette.primary.contrastText,
  },
  bookingcontainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "75px",
    },
    padding: 0,
    marginTop: "8%",
    marginLeft: "8%",
    marginRight: "8%",
    marginBottom: "8%",
    flexGrow: 1,
  },
  mainTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      marginTop: "138px",
      width: "390px",
      height: "71px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "95px",
      width: "300px",
      height: "57px",
    },
  },
  circleIcon: {
    [theme.breakpoints.up("md")]: {
      width: 50,
      height: 50,
    },
    [theme.breakpoints.down("sm")]: {
      width: 50,
      height: 50,
    },
  },
}));

export default function Booking() {
  const classes = useStyles();

  const [swimDates, setswimDates] = useState([]);
  const [memberDetails, setmemberDetails] = useState({});

  async function fetchData() {
    let tempSwimdates = [];
    let currentMember;

    auth.onAuthStateChanged(function (user) {
      if (user) {
        currentMember = auth.currentUser;
        console.log(auth.currentUser);
        //console.log(auth.currentUser);
        var schedule = db.ref(
          "/1Ia7RneQwtyJNVwMvCV8m4X4754TiwOJVus1BUfpC9zw/2021Schedule/"
        );

        var members = db.ref(
          "/1WE3gbAJB-hK57nCRfS2z6aGELaZFv_IkbcwmlRKZtSo/2021/"
        );

        let startDate = new Date();
        let stopDate = new Date();

        stopDate.setDate(startDate.getDate() + 7);

        schedule.once("value", (snapshot) => {
          snapshot.forEach(function (childSnapshot) {
            let testDate = new Date(childSnapshot.val().Date);
            if (
              testDate.valueOf() > startDate.valueOf() &&
              testDate.valueOf() < stopDate.valueOf()
            ) {
              tempSwimdates.push(childSnapshot.val());
              //console.log("swimmers " + testDate.toLocaleDateString());
            }
          });
          setswimDates(tempSwimdates);
        });

        //console.log(swimDates);

        members
          .orderByChild("Email")
          .equalTo(currentMember.email)
          .once("value", (membershot) => {
            //console.debug("member: " + JSON.stringify(membershot.val()[Object.keys(membershot.val())[0]]));
            setmemberDetails(
              membershot.val()[Object.keys(membershot.val())[0]]
            );
          });
      }
    });
  }
  //{JSON.stringify({ isSignedIn, user, providerId }, null, 2)}

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        {
          //SEO goes here!
        }
        <meta charSet="utf-8" />
        <title>Book Comberton Community Swimming Pool Sessions</title>
        <meta
          name="description"
          content="A member run swimming club in Comberton, Cambridge UK"
        />
      </Helmet>
      <IfFirebaseAuthed>
        {() => {
          return (
            <FirebaseAuthConsumer>
              {({ isSignedIn, user, providerId }) => {
                return (
                  <React.Fragment>
                    <Grid container className={classes.root}>
                      <Grid item xs={12} className={classes.gridRow}>
                        <Typography align="center" variant="h1">
                          My Bookings
                        </Typography>
                      </Grid>
                      <Grid container spacing={8} className={classes.gridRow}>
                        <Grid item xs={12} sm={6}>
                          <Paper elevation={0}>
                            <Grid container align="center" direction="column">
                              <Grid item className={classes.circleStyle}>
                                <img
                                  src={booking_info}
                                  alt="Booking Info"
                                  className={classes.circleIcon}
                                />
                              </Grid>

                              <Grid item>
                                <Typography variant="h1">
                                  General Info
                                </Typography>
                              </Grid>

                              <Grid item>
                                <FishItem item_content="test item hoidhasoi hjiosa hiosadh ioh sdaohi sadhiohisoahiohsa hiohioh iohsadhio sdaoih hiosad sdahoiashio " />
                                <FishItem item_content="test item" />
                                <FishItem item_content="test item" />
                                <FishItem item_content="test item" />
                                <FishItem item_content="test item" />
                                <FishItem item_content="test item" />
                                <FishItem item_content="test item" />
                                <FishItem item_content="test item" />
                                <FishItem item_content="test item" />
                                <FishItem item_content="test item" />
                                <FishItem item_content="test item" />
                                <FishItem item_content="test item" />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <Paper elevation={0}>
                            <Grid container align="center" direction="column">
                              <Grid item className={classes.circleStyle}>
                                <img
                                  src={booking_example}
                                  alt="Booking Example"
                                  className={classes.circleIcon}
                                />
                              </Grid>

                              <Grid item>
                                <Typography variant="h1">
                                  Booking Example
                                </Typography>
                              </Grid>
                              <Grid item style={{ marginTop: "25px" }}>
                                <img
                                  src={example_diagram}
                                  alt="Example diagram"
                                  width="90%"
                                  height="100%"
                                />
                              </Grid>
                            </Grid>
                          </Paper>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container className={classes.root}>
                    <Grid item xs={12} className={classes.gridRow}>
                    <Grid container align="center" direction="column">
                              <Grid item className={classes.circleStyle}>
                                <img
                                  src={availability}
                                  alt="Booking Info"
                                  className={classes.circleIcon}
                                />
                              </Grid>

                              <Grid item>
                                <Typography variant="h1">
                                  My booking details
                                </Typography>
                              </Grid>
</Grid>
                      </Grid>
                      <Grid container spacing={8} className={classes.gridRow}>
                        {swimDates.map((item, id) => (
                          <Grid
                            key={id}
                            item
                            xs={12}
                            md={4}
                            style={{ paddingTop: "0px", paddingBottom: "16px" }}
                          >
                            <Session
                              SessionDetails={item}
                              MemberDetails={memberDetails}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                );
              }}
            </FirebaseAuthConsumer>
          );
        }}
      </IfFirebaseAuthed>
      <IfFirebaseUnAuthed>
        <LoginOut />
      </IfFirebaseUnAuthed>
    </React.Fragment>
  );
}
