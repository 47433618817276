import React from "react";
import { Formik, Field, Form, ErrorMessage, FieldArray, getIn } from "formik";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import RowText from "../components/RowText";

import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//excellent example of the arrays: https://gist.github.com/vicasas/410b11874267460484febbbe49bfbb1d

const useStyles = makeStyles((theme) => ({
  dataPicker: {
    marginTop: "16.5px",
  },
  button2: {
    marginTop: "0px",
    width: "20px",
  },
  root: {
    flexGrow: 1,
    marginLeft: "8%",
    marginRight: "8%",
    [theme.breakpoints.up("md")]: {
      marginTop: "138px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "95px",
    },
  },
  dataContainer: {
    flexGrow: 1,
  },
  gridRow: theme.pageMargins,
  intro_text: {
    fontFamily: [
      "Droid Serif",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: 400,
    fontSize: "40px",
    fontStyle: "italic",
    lineHeight: "40px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "60px",
    },
    color: theme.palette.primary.contrastText,
  },
  title_text: {
    fontFamily: [
      "Montserrat",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: 700,
    [theme.breakpoints.up("md")]: {
      fontSize: "75px",
      marginBottom: "50px",
      lineHeight: "75px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      marginBottom: "20px",
      lineHeight: "25px",
    },
    color: theme.palette.primary.contrastText,
  },
  bookingcontainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "75px",
    },
    padding: 0,
    marginTop: "8%",
    marginLeft: "8%",
    marginRight: "8%",
    marginBottom: "8%",
    flexGrow: 1,
  },
  mainTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      marginTop: "138px",
      width: "390px",
      height: "71px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "95px",
      width: "300px",
      height: "57px",
    },
  },
}));

const debug = true;

export default function Signup() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          contactEmail: "",
          address: "",
          phoneNumber: "",
          rotaPublish: true,
          familyMembers: [],
          dutyType: "1st Safety Officer",
          dutyMon: false,
          dutyTue: false,
          dutyWed: false,
          dutyFri: false,
          dutySat: false,
          dutySun: false,
          dutyHol1: false,
          dutyHol2: false,
          holidaysPeriods: [{ start: "", finish: "" }],
          moreDuties: "",
          tandcAcceptance: "",
          comments: "",
        }}
        validationSchema={yup.object({
          contactEmail: yup
            .string("Enter your email")
            .email("Enter a valid email")
            .required("Email is required"),
          firstName: yup
            .string("Enter your first name")
            .min(2, "Your first name should have a minimum of 2 characters")
            .required("First name is required"),
          lastName: yup
            .string("Enter your last name")
            .min(2, "Your last name should have a minimum of 2 characters")
            .required("Last name is required"),
          phoneNumber: yup
            .string("Please enter a phone number")
            .required("Phone number is Required")
            .matches(
              /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
              "Phone number is not valid"
            ),
        })}
        onSubmit={(values) => {
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
        }) => (
          <Form noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography align="center" variant="h1">
                  Membership registration
                </Typography>
              </Grid>

              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <Typography align="center" variant="h3">
                    Contact details
                  </Typography>
                </Grid>
                <Grid item xs={4} />

                <Grid item xs={4} />
                <Grid item xs={4}>
                  <Typography align="center" variant="body">
                    Please enter the name and details of the person who will
                    help out as an admin or safety officer.
                  </Typography>
                </Grid>
                <Grid item xs={4} />

                <Grid item xs={4} />
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    id="firstName"
                    name="firstName"
                    label="First name"
                    value={values.firstName}
                    onChange={handleChange}
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={values.lastName}
                    onChange={handleChange}
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                  />
                </Grid>
                <Grid item xs={4}></Grid>

                <Grid item xs={4} />
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="contactEmail"
                    name="contactEmail"
                    label="Email"
                    value={values.contactEmail}
                    onChange={handleChange}
                    error={touched.contactEmail && Boolean(errors.contactEmail)}
                    helperText={touched.contactEmail && errors.contactEmail}
                  />
                </Grid>
                <Grid item xs={5} />

                <Grid item xs={4} />
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="address"
                    name="address"
                    label="Address incl. postcode"
                    value={values.address}
                    onChange={handleChange}
                    error={touched.address && Boolean(errors.address)}
                    helperText={touched.address && errors.address}
                    multiline
                  />
                </Grid>
                <Grid item xs={5} />

                <Grid item xs={4} />
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone number"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                  />
                </Grid>
                <Grid item xs={5} />

                <Grid item xs={4} />
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  wrap="nowrap"
                  xs={4}
                >
                  <Grid item>
                    <Checkbox
                      fullWidth
                      id="rotaPublish"
                      name="rotaPublish"
                      label="Please include my email address on the rota so others can contact me for duty swaps."
                      checked={values.rotaPublish}
                      onChange={handleChange}
                      error={touched.rotaPublish && Boolean(errors.rotaPublish)}
                      helperText={touched.rotaPublish && errors.rotaPublish}
                    />
                  </Grid>
                  <Grid item>
                    Please include my email address on the rota so others can
                    contact me for duty swaps.
                  </Grid>
                </Grid>
                <Grid item xs={4} />

                <Grid item xs={4} />
                <FieldArray name="familyMembers">
                  {({ push, remove }) => (
                    <Grid
                      item
                      container
                      direction="row"
                      alignItems="flex-start"
                      xs={4}
                    >
                      {values.familyMembers.map((p, index) => {
                        const famName = `familyMembers[${index}].name`;
                        const touchedfamName = getIn(touched, famName);
                        const errorfamName = getIn(errors, famName);

                        const birthDate = `familyMembers[${index}].birthdate`;
                        const touchedbirthDate = getIn(touched, birthDate);
                        const errorbirthDate = getIn(errors, birthDate);

                        const swimPermission = `familyMembers[${index}].swimPermission`;
                        const touchedswimPermission = getIn(
                          touched,
                          swimPermission
                        );
                        const errorswimPermission = getIn(
                          errors,
                          swimPermission
                        );

                        return (
                          <reactFragment>
                            <Grid
                              container
                              direction="row"
                              alignItems="flex-start"
                              spacing={2}
                            >
                              <Grid item xs={6} key={p.id}>
                                <TextField
                                  fullWidth
                                  className={classes.field}
                                  label="Full name"
                                  name={famName}
                                  value={p.famName}
                                  required
                                  helperText={
                                    touchedfamName && errorfamName
                                      ? errorfamName
                                      : ""
                                  }
                                  error={Boolean(
                                    touchedfamName && errorfamName
                                  )}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Grid>
                              <Grid item xs={5} key={p.id}>
                                <TextField
                                  fullWidth
                                  className={classes.dataPicker}
                                  paddingTop="100"
                                  name={birthDate}
                                  type="date"
                                  value={values.familyMembers[index].birthdate}
                                  required
                                  helperText={
                                    touchedbirthDate && errorbirthDate
                                      ? errorbirthDate
                                      : "Under 18? please enter a date of birth"
                                  }
                                  error={Boolean(
                                    touchedbirthDate && errorbirthDate
                                  )}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <Button
                                  className={classes.button2}
                                  marginTop="100px"
                                  type="button"
                                  color="secondary"
                                  variant="outlined"
                                  onClick={() => remove(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  x
                                </Button>
                              </Grid>
                            </Grid>

                            {!(
                              p.name === "" ||
                              values.familyMembers[index].birthdate === "" ||
                              new Date(values.familyMembers[index].birthdate) <
                                new Date(2004, 8, 31)
                            ) && (
                              <Grid
                                item
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                wrap="nowrap"
                              >
                                {new Date(
                                  values.familyMembers[index].birthdate
                                ) > new Date(2004, 8, 31) &&
                                  new Date(
                                    values.familyMembers[index].birthdate
                                  ) < new Date(2010, 8, 31) && (
                                    <reactFragment>
                                      <Grid item>
                                        <Checkbox
                                          fullWidth
                                          id="swimPermission"
                                          name="swimPermission"
                                          checked={
                                            values.familyMembers[index]
                                              .swimPermission
                                          }
                                          onChange={handleChange}
                                          error={
                                            touched.swimPermission &&
                                            Boolean(errors.swimPermission)
                                          }
                                          helperText={
                                            touched.swimPermission &&
                                            errors.swimPermission
                                          }
                                        />
                                      </Grid>
                                      <Grid item>
                                        I give permission for {p.name} to swim
                                        unaccompanied.
                                      </Grid>
                                    </reactFragment>
                                  )}
                                {new Date(
                                  values.familyMembers[index].birthdate
                                ) > new Date(2010, 8, 31) && (
                                  <reactFragment>
                                    <Grid item>
                                      Please note that {p.name} needs to be
                                      accompanied by an adult at all times.
                                    </Grid>
                                  </reactFragment>
                                )}
                              </Grid>
                            )}
                          </reactFragment>
                        );
                      })}

                      <Grid item xs={8}>
                        <Button
                          className={classes.button}
                          type="button"
                          color="tertiary"
                          variant="contained"
                          onClick={() =>
                            push({
                              id: Math.random(),
                              name: "",
                              birthdate: "",
                              swimPermission: false,
                            })
                          }
                        >
                          Add a household member
                        </Button>
                      </Grid>
                      <Grid item xs={4}></Grid>
                    </Grid>
                  )}
                </FieldArray>
                <Grid item xs={4} />

                <Grid item xs={4} />

                <Grid item xs={4}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Duty type</FormLabel>
                    <RadioGroup
                      aria-label="dutyType"
                      name="dutyType"
                      value={values.dutyType}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="1st Safety Officer"
                        control={<Radio />}
                        label="1st Safety Officer"
                      />
                      <FormControlLabel
                        value="2nd Safety Officer"
                        control={<Radio />}
                        label="2nd Safety Officer"
                      />
                      <FormControlLabel
                        value="Administrator"
                        control={<Radio />}
                        label="Administrator"
                      />
                    </RadioGroup>
                  </FormControl>

                  
                </Grid>
                <Grid item xs={4} />

                <Grid item xs={4} />
                <Grid item container xs={4}>
                  <Grid item xs={12}>
                    <Typography>
                      Please tell us when you are not able to help out as a
                      {values.dutyType === "Administrator" && (
                        <reactFragment>n</reactFragment>
                      )}{" "}
                      {values.dutyType}?
                    </Typography>
                  </Grid>

                  {!(values.holidaysPeriods.length === 0) && (
                    <reactFragment>
                      <Grid xs={2}>From:</Grid>
                      <Grid xs={2}>To:</Grid>
                    </reactFragment>
                  )}
                </Grid>
                <Grid item xs={4} />

                <Grid item xs={4} />
                <FieldArray name="holidaysPeriods">
                  {({ push, remove }) => (
                    <Grid
                      item
                      container
                      direction="row"
                      alignItems="flex-start"
                      xs={4}
                    >
                      {values.holidaysPeriods.map((q, index2) => {
                        const holStart = `holidaysPeriods[${index2}].start`;
                        const touchedholStart = getIn(touched, holStart);
                        const errorholStart = getIn(errors, holStart);

                        const holEnd = `holidaysPeriods[${index2}].finish`;
                        const touchedholEnd = getIn(touched, holEnd);
                        const errorholEnd = getIn(errors, holEnd);

                        return (
                          <reactFragment>
                            <Grid
                              container
                              direction="row"
                              alignItems="flex-start"
                              spacing={2}
                            >
                              <Grid item xs={5} key={q.id}>
                                <TextField
                                  fullWidth
                                  className={classes.field}
                                  type="date"
                                  value={values.holidaysPeriods[index2].start}
                                  name={holStart}
                                  required
                                  helperText={
                                    touchedholStart && errorholStart
                                      ? errorholStart
                                      : ""
                                  }
                                  error={Boolean(
                                    touchedholStart && errorholStart
                                  )}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Grid>
                              <Grid item xs={1} />
                              <Grid item xs={5} key={q.id}>
                                <TextField
                                  fullWidth
                                  name={holEnd}
                                  type="date"
                                  value={values.holidaysPeriods[index2].finish}
                                  required
                                  helperText={
                                    touchedholEnd && errorholEnd
                                      ? errorholEnd
                                      : ""
                                  }
                                  error={Boolean(touchedholEnd && errorholEnd)}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <Button
                                  className={classes.button2}
                                  marginTop="100px"
                                  type="button"
                                  color="secondary"
                                  variant="outlined"
                                  onClick={() => remove(index2)}
                                  style={{ cursor: "pointer" }}
                                >
                                  x
                                </Button>
                              </Grid>
                            </Grid>
                          </reactFragment>
                        );
                      })}

                      <Grid item xs={8}>
                        <Button
                          className={classes.button}
                          type="button"
                          color="tertiary"
                          variant="contained"
                          onClick={() =>
                            push({
                              id: Math.random(),
                              start: "",
                              finish: "",
                            })
                          }
                        >
                          Add a period of unavailability
                        </Button>
                      </Grid>
                      <Grid item xs={4}></Grid>
                    </Grid>
                  )}
                </FieldArray>
                <Grid item xs={4} />

                <Grid item xs={12}>
                  <Button color="primary" variant="contained" type="submit">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <RowText />
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            {debug && (
              <>
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
