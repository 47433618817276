import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { auth } from "../elements/firebaseHelpers";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { ClickAwayListener } from "@material-ui/core";
import login_img from "../svg/login.svg";
import CommonButton from "../elements/common_button";
import { theme } from "../ccsc_theme";


import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
  IfFirebaseAuthed,
  IfFirebaseAuthedAnd,
} from "@react-firebase/auth";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: "206px",
    marginLeft: "4%"
  },
  
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
  circleStyle: {
    padding: 0,
    marginBottom: "12px",
    display: "flex",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "50%",
    [theme.breakpoints.up("md")]: {
      width: 75,
      height: 75,
    },
    [theme.breakpoints.down("sm")]: {
      width: 75,
      height: 75,
    },
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
  circleIcon: {
    [theme.breakpoints.up("md")]: {
      width: 50,
      height: 50,
    },
    [theme.breakpoints.down("sm")]: {
      width: 50,
      height: 50,
    },
  },
  mainTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      width: "180px",
      height: "57px",
    },
    [theme.breakpoints.down("sm")]: {
      width: 180,
      height: 50,
    },
  },
  enterBox: {
    width: "292px",
  },
  customTF: {
    [theme.breakpoints.up("md")]: {
      width: 210,
      height: 34,
    },
    [theme.breakpoints.down("sm")]: {
      width: 210,
      height: 34,
    },
    border: "1px solid #0D2C54",
    overflow: "hidden",
    borderRadius: 5,
    backgroundColor: "#E7EAEE",
  },
  resize: {
    textTransform: "none",
    fontFamily: [
      "Roboto Slab",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: 400,
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
      lineHeight: 1.3,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: 1.3,
    },
  },
}));

export default function LoginOut() {
  const [errorMessage, setErrormessage] = useState("");
  const classes = useStyles();
  const [messages, setMessages] = React.useState({
    forgottenPassword: "Please enter an email address and then click",
    wrongEmailformat: "",
    emailHelpertext: "",
    emailErrorindicator: false,
    pwResetsent: "",
  });

  const [values, setValues] = React.useState({
    password: "",
    email: "",
    emailError: true,
    passwordblank: true,
    emailblank: true,
    showPassword: false,
  });

  const sendPwReminder = () => {
    auth.sendPasswordResetEmail(values.email);
    setMessages({
      ...messages,
      pwResetsent: "Email sent, Please check your inbox (or spam folder)",
    });
  };

  const handleLogin = () => {
    //check both values (should not be able to press it if there are issues)

    //login with firebase
    auth
      .signInWithEmailAndPassword(values.email, values.password)
      .then((userCredential) => {
        // Signed in
        //auth.sendPasswordResetEmail(values.email);
        // ...
      })
      .catch((error) => {
        setErrormessage(error.message);
        //var errorCode = error.code;
        //var errorMessage = error.message;
      });
  };

  const handleChange = (prop) => (event) => {
    let error = false;
    let blank = false;
    var mailformat =
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    if (event.target.value === "") blank = true;

    if (prop === "email") {
      if (!event.target.value.match(mailformat)) error = true;
      setValues({
        ...values,
        [prop]: event.target.value,
        [prop + "blank"]: blank,
        [prop + "Error"]: error,
      });
      if (error) {
        setMessages({
          ...messages,
          forgottenPassword:
            "Please enter a correct email address and then click",
          emailHelpertext: "Email is incomplete/has an error.",
        });
      } else {
        setMessages({
          ...messages,
          forgottenPassword: "To receive a password reset link by email click",
          emailErrorindicator: false,
          emailHelpertext: "",
        });
      }
      if (blank)
        setMessages({
          ...messages,
          forgottenPassword: "Please enter an email address and then click",
          emailErrorindicator: false,
        });
    } else {
      setValues({
        ...values,
        [prop]: event.target.value,
        [prop + "blank"]: blank,
      });
      if (values.emailError)
        setMessages({
          ...messages,
          emailErrorindicator: true,
          emailHelpertext: "Email contains an error.",
        });
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickAway = (event) => {
    if (values.emailError)
      setMessages({
        ...messages,
        emailErrorindicator: true,
        emailHelpertext: "Email contains an error.",
      });
    if (values.emailblank)
      setMessages({
        ...messages,
        emailErrorindicator: true,
        emailHelpertext: "Please enter an email address.",
      });
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.root}
    >
      <Grid item className={classes.circleStyle}>
        <img src={login_img} alt="Login" className={classes.circleIcon} />
      </Grid>
      <Grid item className={classes.mainTitle}>
        <Typography variant="h1">LOG IN</Typography>
      </Grid>

      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        className={classes.enterBox}
      >
        <Grid item>
          <Typography variant="h4">Email</Typography>
        </Grid>
        <Grid item>
          <ClickAwayListener onClickAway={handleClickAway}>
            <TextField
              id="email"
              padding="0"
              variant="outlined"
              error={messages.emailErrorindicator}
              required
              onChange={handleChange("email")}
              helperText={messages.emailHelpertext}
              InputProps={{
                shrink: "true",
                style: {
                  width: 210,
                  height: 34,
                  fontSize: 14,
                  border: "1px solid #0D2C54",
                  overflow: "hidden",
                  borderRadius: 5,
                  backgroundColor: "#E7EAEE",
                },
              }}
            />
          </ClickAwayListener>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        className={classes.enterBox}
        style={{ marginTop: 26 }}
      >
        <Grid item>
          <Typography variant="h4">Password</Typography>
        </Grid>
        <Grid item>
          <FormControl>
            <Input
              label="Password"
              id="standard-adornment-password"
              disableUnderline="true"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              required
              onChange={handleChange("password")}
              className={classes.customTF}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: 39 }}>
        <CommonButton
          disabled={values.emailError}
          style={{ color: "#ffffff", width: 121 }}
          onClick={handleLogin}
        >
          Login
        </CommonButton>
      </Grid>
      <Grid item style={{ marginTop: 26 }}>
        <Typography className={classes.resize}>
          Forgotten your password?{" "}
        </Typography>
      </Grid>
      <Grid
        item
        container
        justify="center"
        direction="row"
        style={{ marginTop: 5 }}
      >
        <Grid item>
          <div
            dangerouslySetInnerHTML={{ __html: messages.forgottenPassword }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="h4"
            onClick={sendPwReminder}
            classes={{
              label: classes.resize, // class name, e.g. `classes-nesting-label-x`
            }}
            style={{
              paddingTop: 1,
              paddingLeft: 5,
              justifyContent: "center",
              fontWeight: 700,
              width: 200
            }}
          >
            <b>here</b>
          </Button>
        </Grid>

      </Grid>
      <Grid item>
          <div dangerouslySetInnerHTML={{ __html: messages.pwResetsent }} />
        </Grid>
      <Grid item>
        <Typography variant="h6">
          {errorMessage !== "" && errorMessage}
        </Typography>
        <IfFirebaseAuthed>
          {() => {
            return <div>You are authenticated</div>;
          }}
        </IfFirebaseAuthed>
      </Grid>
    </Grid>
  );
}
