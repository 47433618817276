export default {
  apiKey: "AIzaSyD-wOumWIsy0p-EIiQYBlI0raOEXIlgFJ0",
  authDomain: "ccsc-webapp.firebaseapp.com",
  databaseURL: "https://ccsc-webapp-21c3e.europe-west1.firebasedatabase.app/",
  projectId: "ccsc-webapp",
  storageBucket: "ccsc-webapp.appspot.com",
  messagingSenderId: "580213695031",
  appId: "1:580213695031:web:43d20ec7fd3723711d95b2",
  measurementId: "G-9F7265MKVF"
};

