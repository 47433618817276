import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Button from "@material-ui/core/Button";

import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import firebase from "firebase/app";
import { FirebaseAuthConsumer } from "@react-firebase/auth";

import { theme } from "../ccsc_theme";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    marginLeft: "8%",
    marginRight: "8%",
    flexGrow: 1,

  },
  title: {
    flexGrow: 1,
    marginLeft: "8%",
    marginTop: "4px",
    marginBottom: "4px",
    color: 'white',
  },
  login: {
    paddingRight: "12px",
    paddingLeft: "12px",
    marginTop: "4px",
    marginBottom: "4px",
  },

  button_root: {
    color: 'white',
  },

  
  button_logo: {
    color: 'white',
    fontSize: 18,
  },

  button_logo_label: {
    color: 'white',
  },

  label: {
    textTransform: 'capitalize',
    color: 'white',
  },

  appBar: {},
}));

export default function Header() {
  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    setAnchorEl(null);
    setAuth(!auth);
  };

  const handleLogin = (event) => {
    setAnchorEl(null);
    setAuth(true);
    const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "visible",
        callback: (response) => {},
      }
    );
    // reCAPTCHA solved, allow signInWithPhoneNumber.
    firebase.auth().signInWithPopup(recaptchaVerifier);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="fixed">
      <Toolbar className={classes.root}>
        <Button variant="h6" edge="start" classes={{
                  root: classes.button_logo, // class name, e.g. `classes-nesting-root-x`
                  label: classes.button_logo_label, // class name, e.g. `classes-nesting-label-x`
                }} href="/" disableRipple>
          CCSC
        </Button>
        <Typography className={classes.title}>

        </Typography>

        
        </Toolbar>
    </AppBar>
  );
}
