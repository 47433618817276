import React, { useState, useEffect } from "react";
import {
  useFormikContext,
  Formik,
  Field,
  Form,
  ErrorMessage,
  FieldArray,
  getIn,
} from "formik";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import PaymentSuccess from "../pages/PaymentSuccess";

import DeleteIcon from "@material-ui/icons/Delete";
import {
  ElementsConsumer,
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { testWebsite } from "../functions/testFunctions";

import firebase from "firebase";

if (testWebsite() === true) {
  firebase.functions().useEmulator("http://localhost:5001");
}

//instructions for adding the payment facility
//https://blog.logrocket.com/integrating-stripe-react-stripe-js/

const useStyles = makeStyles((theme) => ({
  dataPicker: {
    marginTop: "16.5px",
  },
  button2: {
    marginTop: "0px",
    width: "10px",
    padding: "0",
  },
  root: {
    flexGrow: 1,
    marginLeft: "4%",
    marginRight: "4%",
    [theme.breakpoints.up("md")]: {
      marginTop: "138px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "95px",
    },
  },
  dataContainer: {
    flexGrow: 1,
  },
  gridRow: theme.pageMargins,
  paperLeft: {
    textAlign: "left",
    color: theme.palette.text.primary,
    width: "100%",
    margin: "auto",
  },
  paperRight: {
    textAlign: "left",
    color: theme.palette.text.primary,
    width: "100%",
    margin: "auto",
  },
  columnLeft: {
    padding: theme.spacing(0),
    paddingRight: "4px",

    flexGrow: 1,
  },

  columnRight: {
    padding: theme.spacing(0),
    paddingLeft: "4px",

    flexGrow: 1,
  },
  coreColumnRow: {
    width: "100%",

    paddingTop: "12px",

    [theme.breakpoints.up("md")]: {
      maxWidth: "450px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "350px",
    },
  },
  closeButton: {
    padding: theme.spacing(0),
  },
  subHeading: {
    paddingTop: "32px",
  },
  headingRow: {
    width: "100%",
    maxWidth: "350px",
  },
  explanation: {
    paddingTop: "10px",
    variant: "body",
    align: "left",
  },
  errorMessage: {
    paddingTop: "10px",
    variant: "body",
    align: "left",
    color: "red",
  },
  checkboxLabel: {
    paddingTop: "8px",
    variant: "body",
    align: "left",
  },
}));

export default function SignupForm(props) {
  const classes = useStyles();
  var clientSecret;

  const debug = testWebsite();

  let checkout;
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [isPaymentSuccess, setPaymentSuccess] = useState(false);
  const [isPaymentFailure, setPaymentFailure] = useState(false);
  const [isExistingFirst, setExistingFirst] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  if (testWebsite() === true) {
    firebase.functions().useEmulator("localhost", 5001);
  }

//  const handleEmailBlur = async (emailCheck) => {

    
//    var checkFirst = firebase.functions().httpsCallable("checkFirst");
//    var requestBody = {
//      contactEmail: emailCheck,
//    };

    //const checkFirstResult = await checkFirst(requestBody);

//    console.log(checkFirstResult);

//    if (checkFirstResult.data === true) {
//      setExistingFirst(true);
//    } else {
//      setExistingFirst(false);
//    }
//  };

  const payMoney = async (e) => {
    setPaymentLoading(true);

    if (testWebsite()) {
      checkout = "stripeCheckout_test";
    } else {
      checkout = "stripeCheckout_test";
    }

    //console.log(checkout);

    var addMessage = firebase.functions().httpsCallable(checkout);

    //addMessage(e).then(function (result) {
    //  console.log(result);
    //  clientSecret = result.data.clientSecret;
    //})

    const secretData = await addMessage(e);
    clientSecret = secretData.data.clientSecret;

    const paymentResult = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: e.firstName + " " + e.lastName,
        },
      },
    });
    setPaymentLoading(false);
    if (paymentResult.error) {
      setPaymentFailure(true);
    } else {
      if (paymentResult.paymentIntent.status === "succeeded") {
        setPaymentSuccess(true);
      }
    }
  };

  return (
    <reactFragment>
      {!isPaymentSuccess && (
        <ElementsConsumer>
          {({ elements, stripe }) => (
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                contactEmail: "",
                address: "",
                phoneNumber: "",
                rotaPublish: true,
                familyMembers: [],
                dutyType: "1st Safety Officer",
                dutyMon: false,
                dutyTue: false,
                dutyWed: false,
                dutyFri: false,
                dutySat: false,
                dutySun: false,
                dutyHol1: false,
                dutyHol2: false,
                dutydateSecond: "28th of April at 13:30",
                theorydateSecond: "16th of April at 19:30",
                holidaysPeriods: [],
                moreDuties: "",
                tandcAcceptance: false,
                comments: "",
                donation: "0",
              }}
              validationSchema={yup.object({
                contactEmail: yup
                  .string("Enter your email")
                  .email("Enter a valid email")
                  .required("Email is required"),

                firstName: yup
                  .string("Enter your first name")
                  .min(
                    2,
                    "Your first name should have a minimum of 2 characters"
                  )
                  .required("First name is required"),
                lastName: yup
                  .string("Enter your last name")
                  .min(
                    2,
                    "Your last name should have a minimum of 2 characters"
                  )
                  .required("Last name is required"),
                phoneNumber: yup
                  .string("Please enter a phone number")
                  .required("Phone number is Required")
                  .matches(
                    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
                    "Phone number is not valid"
                  ),
                tandcAcceptance: yup
                  .boolean()
                  .oneOf(
                    [true],
                    "Members must accept the terms and conditions"
                  ),
              })}
              onSubmit={payMoney}
            >
              {({
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                isValid,
                setFieldValue,
              }) => (
                <Form noValidate autoComplete="off">
                  {/* Start of a row */}
                  <Grid
                    id="contactDetails"
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs className={classes.columnLeft}>
                      <Typography align="center" variant="h1">
                        2024 Membership Registration v1.0
                        {isExistingFirst && <reactFragment>Hebbes</reactFragment>}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.headingRow}
                  >
                    <Grid item xs className={classes.columnLeft}>
                      <Typography
                        align="center"
                        variant="h4"
                        className={classes.subHeading}
                      >
                        Contact details
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs className={classes.columnLeft}>
                      <Typography className={classes.explanation}>
                        Please enter the name and details of the person who will
                        help out as an admin or safety officer.
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs className={classes.columnLeft}>
                      <TextField
                        required
                        fullWidth
                        id="firstName"
                        name="firstName"
                        label="First name"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                      />
                    </Grid>
                    <Grid item xs className={classes.columnRight}>
                      <TextField
                        required
                        fullWidth
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </Grid>
                  </Grid>
                  {/* End of a row 
                        {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs>
                      <TextField
                        required
                        fullWidth
                        id="contactEmail"
                        name="contactEmail"
                        label="Email"
                        value={values.contactEmail}
                        onChange={handleChange}
                        //onBlur={(e) => {handleBlur(e); handleEmailBlur(values.contactEmail); console.log(values.contactEmail)}}
                        error={
                          touched.contactEmail && Boolean(errors.contactEmail)
                        }
                        helperText={touched.contactEmail && errors.contactEmail}
                      />
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs>
                      <TextField
                        required
                        fullWidth
                        id="address"
                        name="address"
                        label="Address incl. postcode"
                        value={values.address}
                        onChange={handleChange}
                        error={touched.address && Boolean(errors.address)}
                        helperText={touched.address && errors.address}
                        multiline
                      />
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs>
                      <TextField
                        required
                        fullWidth
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Phone number"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.phoneNumber && Boolean(errors.phoneNumber)
                        }
                        helperText={touched.phoneNumber && errors.phoneNumber}
                      />
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                    wrap="nowrap"
                  >
                    <Grid item>
                      <Checkbox
                        id="rotaPublish"
                        name="rotaPublish"
                        label="Please include my email address on the rota so others can contact me for duty swaps."
                        checked={values.rotaPublish}
                        onChange={handleChange}
                        error={
                          touched.rotaPublish && Boolean(errors.rotaPublish)
                        }
                        helperText={touched.rotaPublish && errors.rotaPublish}
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.checkboxLabel}>
                        Please include my email address on the rota so others
                        can contact me for duty swaps.
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}{" "}
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs className={classes.columnLeft}>
                      <Typography
                        align="center"
                        variant="h4"
                        className={classes.subHeading}
                      >
                        Household members
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs className={classes.columnLeft}>
                      <Typography className={classes.explanation}>
                        Add the names of optional additional household members
                        by pressing the button below:
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}
                  <FieldArray name="familyMembers">
                    {({ push, remove }) => (
                      <reactFragment>
                        {values.familyMembers.map((p, index) => {
                          const famName = `familyMembers[${index}].name`;
                          const touchedfamName = getIn(touched, famName);
                          const errorfamName = getIn(errors, famName);

                          const birthDate = `familyMembers[${index}].birthdate`;
                          const touchedbirthDate = getIn(touched, birthDate);
                          const errorbirthDate = getIn(errors, birthDate);

                          const swimPermission = `familyMembers[${index}].swimPermission`;
                          const touchedswimPermission = getIn(
                            touched,
                            swimPermission
                          );
                          const errorswimPermission = getIn(
                            errors,
                            swimPermission
                          );

                          return (
                            <reactFragment>
                              <Grid
                                direction="row"
                                item
                                container
                                justifyContent="space-between"
                                alignItems="flex-start"
                                className={classes.coreColumnRow}
                                wrap="nowrap"
                              >
                                <Grid
                                  item
                                  key={p.id}
                                  className={classes.columnLeft}
                                >
                                  <TextField
                                    fullWidth
                                    label="Full name"
                                    name={famName}
                                    value={p.famName}
                                    required
                                    helperText={
                                      touchedfamName && errorfamName
                                        ? errorfamName
                                        : ""
                                    }
                                    error={Boolean(
                                      touchedfamName && errorfamName
                                    )}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  className={classes.columnRight}
                                  key={p.id}
                                >
                                  <TextField
                                    fullWidth
                                    name={birthDate}
                                    type="date"
                                    value={
                                      values.familyMembers[index].birthdate
                                    }
                                    required
                                    style={{ paddingTop: "16px" }}
                                    helperText={
                                      touchedbirthDate && errorbirthDate
                                        ? errorbirthDate
                                        : "Under 18? please enter a date of birth"
                                    }
                                    error={Boolean(
                                      touchedbirthDate && errorbirthDate
                                    )}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  className={classes.closeButton}
                                  key={p.id}
                                >
                                  <IconButton
                                    onClick={() => remove(index)}
                                    aria-label="delete"
                                    style={{ paddingTop: "24px" }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>

                              {!(
                                p.name === "" ||
                                values.familyMembers[index].birthdate === "" ||
                                new Date(
                                  values.familyMembers[index].birthdate
                                ) < new Date(2006, 8, 31)
                              ) && (
                                <reactFragment>
                                  {new Date(
                                    values.familyMembers[index].birthdate
                                  ) > new Date(2006, 8, 31) &&
                                    new Date(
                                      values.familyMembers[index].birthdate
                                    ) < new Date(2012, 8, 31) && (
                                      <Grid
                                        item
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                        className={classes.coreColumnRow}
                                        wrap="nowrap"
                                      >
                                        <Grid item>
                                          <Checkbox
                                            id="swimPermission"
                                            name={swimPermission}
                                            checked={
                                              values.familyMembers[index]
                                                .swimPermission
                                            }
                                            onChange={handleChange}
                                            error={
                                              touched.swimPermission &&
                                              Boolean(errors.swimPermission)
                                            }
                                            helperText={
                                              touched.swimPermission &&
                                              errors.swimPermission
                                            }
                                          />
                                        </Grid>
                                        <Grid item>
                                          <Typography
                                            className={classes.checkboxLabel}
                                          >
                                            I give permission for {p.name} to
                                            swim unaccompanied.
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    )}
                                  {new Date(
                                    values.familyMembers[index].birthdate
                                  ) > new Date(2012, 8, 31) && (
                                    <Grid
                                      item
                                      container
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="flex-start"
                                      className={classes.coreColumnRow}
                                      wrap="nowrap"
                                    >
                                      <Grid item>
                                        <Typography>
                                          Please note that {p.name} needs to be
                                          accompanied by an adult at all times.
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  )}
                                </reactFragment>
                              )}
                            </reactFragment>
                          );
                        })}

                        {/* Start of a row */}
                        <Grid
                          item
                          container
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          className={classes.coreColumnRow}
                        >
                          <Grid item xs>
                            <Box textAlign="center">
                              <Button
                                className={classes.button}
                                type="button"
                                color="tertiary"
                                variant="contained"
                                onClick={() =>
                                  push({
                                    id: Math.random(),
                                    name: "",
                                    birthdate: "",
                                    swimPermission: false,
                                  })
                                }
                              >
                                Add a household member
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                        {/* End of a row 
      
      */}
                      </reactFragment>
                    )}
                  </FieldArray>
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs className={classes.columnLeft}>
                      <Typography
                        align="center"
                        variant="h4"
                        className={classes.subHeading}
                      >
                        Duty details
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs className={classes.columnLeft}>
                      <Typography
                        align="left"
                        variant="body2"
                        className={classes.explanation}
                      >
                        All members will need to help out a couple of times a
                        year as a safety officer or an administrator. First
                        safety officers receive a 2 day RLSS certified training
                        course and in return will get a 50% discount on the
                        memberhip fee. The course for 1st safety officers will
                        run on 13 and 14 of April for those who did not take the
                        course last year. 2nd safety officers need to attend training on 28 of April or 5th of May (13:30) as well as a theory session either 16th or 23rd of April 19:30-21:30.
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid
                      item
                      xs
                      className={classes.columnLeft}
                      style={{ marginTop: "24px" }}
                    >
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          This year I/we will help out as a:
                        </FormLabel>
                        <RadioGroup
                          aria-label="dutyType"
                          name="dutyType"
                          value={values.dutyType}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="1st Safety Officer"
                            control={<Radio />}
                            label="1st Safety Officer"
                          />
                          <FormControlLabel
                            value="2nd Safety Officer"
                            control={<Radio />}
                            label="2nd Safety Officer"
                          />
                          <FormControlLabel
                            value="Administrator"
                            control={<Radio />}
                            label="Administrator"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* End of a row
                   */}
                  {/* Start of a row */}
                  { values.dutyType === "2nd Safety Officer" &&
                  
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid
                      item
                      xs
                      className={classes.columnLeft}
                      style={{ marginTop: "24px" }}
                    >
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          I will attend the second safety officer course on:
                        </FormLabel>
                        <RadioGroup
                          aria-label="dutydateSecond"
                          name="dutydateSecond"
                          value={values.dutydateSecond}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="28th of April at 13:30"
                            control={<Radio />}
                            label="28th of April at 13:30"
                          />
                          <FormControlLabel
                            value="5th of May at 13:30"
                            control={<Radio />}
                            label="5th of May at 13:30"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  }
                  {/* End of a row
                   */}

                  {/* Start of a row */}
                  { values.dutyType === "2nd Safety Officer" &&
                  
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid
                      item
                      xs
                      className={classes.columnLeft}
                      style={{ marginTop: "24px" }}
                    >
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          I will attend the second safety officer theory evening on:
                        </FormLabel>
                        <RadioGroup
                          aria-label="theorydateSecond"
                          name="theorydateSecond"
                          value={values.theorydateSecond}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="16th of April at 19:30"
                            control={<Radio />}
                            label="16th of April at 19:30"
                          />
                          <FormControlLabel
                            value="23rd of April at 19:30"
                            control={<Radio />}
                            label="23rd of April at 19:30"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                  }
                  {/* End of a row
                   */}
                   

                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs className={classes.columnLeft}>
                      <FormControl
                        component="fieldset"
                        className={classes.formControl}
                        style={{ marginTop: "24px" }}
                      >
                        <FormLabel component="legend">
                          On which days you can help out as a
                          {values.dutyType === "Administrator" && (
                            <reactFragment>n</reactFragment>
                          )}{" "}
                          {values.dutyType}?
                        </FormLabel>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.dutyMon}
                                onChange={handleChange}
                                name="dutyMon"
                              />
                            }
                            label="Monday 6:30-8:30pm"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.dutyTue}
                                onChange={handleChange}
                                name="dutyTue"
                              />
                            }
                            label="Tuesday (Lane swimming) 6:30-8:30pm"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.dutyWed}
                                onChange={handleChange}
                                name="dutyWed"
                              />
                            }
                            label="Wednesday 6:30-8:30pm"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.dutyFri}
                                onChange={handleChange}
                                name="dutyFri"
                              />
                            }
                            label="Friday 6:30-8:30pm"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.dutySat}
                                onChange={handleChange}
                                name="dutySat"
                              />
                            }
                            label="Saturday 3:00-5:00pm"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.dutySun}
                                onChange={handleChange}
                                name="dutySun"
                              />
                            }
                            label="Sunday 3:00-5:00pm"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.dutyHol1}
                                onChange={handleChange}
                                name="dutyHol1"
                              />
                            }
                            label="School holidays Tuesday 2:30-4:30pm"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.dutyHol2}
                                onChange={handleChange}
                                name="dutyHol2"
                              />
                            }
                            label="School holidays Thursday 2:30-4:30pm"
                          />
                        </FormGroup>

                        <FormHelperText>
                          Please note that from 14 August the evening opening
                          times change to 6:30pm - 7:45pm.
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs className={classes.columnLeft}>
                      <Typography>
                        Please tell us when you are not able to help out as a
                        {values.dutyType === "Administrator" && (
                          <reactFragment>n</reactFragment>
                        )}{" "}
                        {values.dutyType}?
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}
                  {!(values.holidaysPeriods.length === 0) && (
                    <Grid
                      item
                      container
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      className={classes.coreColumnRow}
                    >
                      <Grid item xs className={classes.columnLeft}>
                        <Typography>From:</Typography>
                      </Grid>
                      <Grid item xs className={classes.columnRight}>
                        <Typography>To:</Typography>
                      </Grid>
                    </Grid>
                  )}
                  <FieldArray name="holidaysPeriods">
                    {({ push, remove }) => (
                      <reactFragment>
                        {values.holidaysPeriods.map((q, index2) => {
                          const holStart = `holidaysPeriods[${index2}].start`;
                          const touchedholStart = getIn(touched, holStart);
                          const errorholStart = getIn(errors, holStart);

                          const holEnd = `holidaysPeriods[${index2}].finish`;
                          const touchedholEnd = getIn(touched, holEnd);
                          const errorholEnd = getIn(errors, holEnd);

                          return (
                            <reactFragment>
                              <Grid
                                direction="row"
                                item
                                container
                                justifyContent="space-between"
                                alignItems="flex-start"
                                className={classes.coreColumnRow}
                                wrap="nowrap"
                              >
                                <Grid
                                  item
                                  key={q.id}
                                  className={classes.columnLeft}
                                >
                                  <TextField
                                    fullWidth
                                    className={classes.field}
                                    type="date"
                                    value={values.holidaysPeriods[index2].start}
                                    name={holStart}
                                    required
                                    helperText={
                                      touchedholStart && errorholStart
                                        ? errorholStart
                                        : ""
                                    }
                                    error={Boolean(
                                      touchedholStart && errorholStart
                                    )}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  className={classes.columnRight}
                                  key={q.id}
                                >
                                  <TextField
                                    fullWidth
                                    name={holEnd}
                                    type="date"
                                    value={
                                      values.holidaysPeriods[index2].finish
                                    }
                                    required
                                    helperText={
                                      touchedholEnd && errorholEnd
                                        ? errorholEnd
                                        : ""
                                    }
                                    error={Boolean(
                                      touchedholEnd && errorholEnd
                                    )}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  className={classes.closeButton}
                                  key={q.id}
                                >
                                  <IconButton
                                    onClick={() => remove(index2)}
                                    aria-label="delete"
                                    style={{ paddingTop: "12px" }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </reactFragment>
                          );
                        })}
                        {/* Start of a row */}
                        <Grid
                          item
                          container
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          className={classes.coreColumnRow}
                        >
                          <Grid item xs>
                            <Box textAlign="center">
                              <Button
                                className={classes.button}
                                type="button"
                                color="tertiary"
                                variant="contained"
                                onClick={() =>
                                  push({
                                    id: Math.random(),
                                    start: "",
                                    finish: "",
                                  })
                                }
                              >
                                Add a period of unavailability
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </reactFragment>
                    )}
                  </FieldArray>
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs className={classes.columnLeft}>
                      <Typography
                        align="center"
                        variant="h4"
                        className={classes.subHeading}
                      >
                        Declaration
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs className={classes.columnLeft}>
                      <Typography
                        variant="body"
                        className={classes.explanation}
                      >
                        The Swimming Pool Committee does not accept any
                        responsibility for loss or damage to any property left
                        in the pool area or losses consequent upon such loss or
                        damage, or for injury or death of any person except
                        directly caused through the negligence of the swimming
                        Pool Committee{" "}
                        <a
                          href="CCSC_Rules_book.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          (Rule 19 Members Rulebook)
                        </a>
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.explanation}
                      >
                        DECLARATION: I agree with the terms and Conditions set
                        out above and will abide by the Rules of the Club as set
                        out in the{" "}
                        <a
                          href="CCSC_Rules_book.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Members Rulebook
                        </a>{" "}
                        (clicking the link opens the Rulebook in a new
                        tab/window).
                      </Typography>
                      <Typography
                        variant="body2"
                        className={classes.explanation}
                      >
                        In becoming a CCSC member I hereby agree to be bound by
                        the terms of the Data Protection Act 1998 (as amended by
                        the General Data Protection Regulations 2018) in respect
                        of not sharing or disclosing any personal data of CCSC
                        Members or associates outside of CCSC without their
                        prior consent. I further agree to CCSC using my personal
                        data (including my name and relevant contact details) to
                        share with other CCSC members for administrative
                        purposes; (e.g. Admin / Safety Officer Duty rosters and
                        amendments). CCSC for its part will ensure the
                        confidentiality and privacy of all personal data it
                        holds on behalf of its Members.
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                    wrap="nowrap"
                  >
                    <Grid item>
                      <Checkbox
                        id="tandcAcceptance"
                        required
                        name="tandcAcceptance"
                        label="I accept the declaration above on behalf of my household."
                        checked={values.tandcAcceptance}
                        onChange={handleChange}
                        error={
                          touched.tandcAcceptance &&
                          Boolean(errors.tandcAcceptance)
                        }
                        helperText={
                          touched.tandcAcceptance && errors.tandcAcceptance
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.checkboxLabel}>
                        I accept the declaration above
                        {values.familyMembers.length > 0 && (
                          <reactFragment>
                            on behalf of my household
                          </reactFragment>
                        )}
                        .
                      </Typography>
                      {touched.tandcAcceptance && errors.tandcAcceptance && (
                        <Typography
                          variant="body2"
                          className={classes.explanation}
                        >
                          All members must accept the terms and conditions
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}

                              {/* Start of a row */}
                              <Grid
                          item
                          container
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          className={classes.coreColumnRow}
                        >
                          <Grid item xs className={classes.columnLeft}>
                            <Typography
                              align="center"
                              variant="h4"
                              className={classes.subHeading}
                            >
                              Your membership fee for 2024 is: {}
                              {values.dutyType === "1st Safety Officer" &&
                                values.familyMembers.length > 0 && (
                                  <reactFragment>
                                    £27.50 (includes a 50% discount for
                                    volunteering as a 1st Safety Officer)
                                  </reactFragment>
                                )}
                              {values.dutyType !== "1st Safety Officer" &&
                                values.familyMembers.length > 0 && (
                                  <reactFragment>£70</reactFragment>
                                )}
                              {values.dutyType === "1st Safety Officer" &&
                                values.familyMembers.length === 0 && (
                                  <reactFragment>
                                    £20 (includes a 50% discount for
                                    volunteering as a 1st Safety Officer)
                                  </reactFragment>
                                )}
                              {values.dutyType !== "1st Safety Officer" &&
                                values.familyMembers.length === 0 && (
                                  <reactFragment>£55</reactFragment>
                                )}
                            </Typography>
                          </Grid>
                        </Grid>
                        {/* End of a row */}
                                    {/* Start of a row */}
                                    <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    <Grid item xs>
                      <TextField
                        required
                        fullWidth
                        id="donation"
                        name="donation"
                        label="Add a donation? Pls enter the amount:"
                        value={values.donation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.donation && Boolean(errors.donation)
                        }
                        helperText={touched.donation && errors.donation}
                      />
                    </Grid>
                  </Grid>
                  {/* End of a row 
      
      */}

                  {Object.getOwnPropertyNames(errors).length === 0 &&
                    !isPaymentSuccess &&
                   /* touched.contactEmail && */(
                      <reactFragment>




                        <Box
                          textAlign="center"
                          style={{
                            marginTop: "24px",
                            paddingTop: "6px",
                            paddingBottom: "6px",
                            border: "1px solid #1B5AAD",
                          }}
                        >
                          <CardElement
                            className="card"
                            options={{
                              style: {
                                base: {
                                  color: "#32325d",
                                  fontFamily:
                                    '"Helvetica Neue", Helvetica, sans-serif',
                                  fontSmoothing: "antialiased",
                                  fontSize: "16px",
                                  "::placeholder": {
                                    color: "#aab7c4",
                                  },
                                },
                                invalid: {
                                  color: "#fa755a",
                                  iconColor: "#fa755a",
                                },
                              },
                            }}
                          />
                        </Box>

                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="flex-start"
                          className={classes.coreColumnRow}
                          wrap="nowrap"
                        >
                          {!isPaymentLoading && (
                            <Grid item xs>
                              <Box
                                textAlign="center"
                                style={{ paddingTop: "12px" }}
                              >
                                <Button
                                  color="primary"
                                  variant="contained"
                                  type="submit"
                                  disabled={
                                    (Object.getOwnPropertyNames(errors)
                                      .length !==
                                      0) &
                                    !stripe &
                                    !isPaymentLoading
                                  }
                                >
                                  Pay
                                </Button>
                              </Box>
                            </Grid>
                          )}

                          {Object.getOwnPropertyNames(errors).length === 0 &&
                            isPaymentLoading && (
                              <Grid
                                item
                                container
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                className={classes.coreColumnRow}
                              >
                                <Grid item xs className={classes.columnLeft}>
                                  <Typography
                                    align="center"
                                    variant="h4"
                                    className={classes.subHeading}
                                  >
                                    Processing payment
                                  </Typography>
                                </Grid>
                              </Grid>
                            )}
                        </Grid>
                        <Grid
                          item
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="flex-start"
                          className={classes.coreColumnRow}
                          wrap="nowrap"
                        >
                          <Grid item xs>
                            <img
                              src="https://cdn.brandfolder.io/KGT2DTA4/at/rvgw5pc69nhv9wkh7rw8ckv/Powered_by_Stripe_-_blurple.svg"
                              width="30%"
                            />
                          </Grid>
                        </Grid>
                        
                      </reactFragment>
                    )}
                  {Object.getOwnPropertyNames(errors).length === 0 &&
                    isPaymentSuccess && (
                      <Grid
                        item
                        container
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        className={classes.coreColumnRow}
                      >
                        <Grid item xs className={classes.columnLeft}>
                          <Typography
                            align="center"
                            variant="h4"
                            className={classes.subHeading}
                          >
                            Payment received, with thanks! You will receive an
                            email confirming your membership. If you do not
                            receive this then please contact us at:
                            contact@comberton-pool.org.uk
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  {/* Start of a row */}
                  <Grid
                    item
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    className={classes.coreColumnRow}
                  >
                    {Object.getOwnPropertyNames(errors).length !== 0 && (
                      <Grid item xs className={classes.columnLeft}>
                        <Typography
                          variant="h4"
                          className={classes.errorMessage}
                        >
                          Before you can pay you need to correct the following
                          errors:
                        </Typography>
                      </Grid>
                    )}
                    {errors.hasOwnProperty("tandcAcceptance") && (
                      <Grid item xs className={classes.columnLeft}>
                        <Typography
                          variant="body2"
                          className={classes.errorMessage}
                        >
                          - Accept the terms and conditions{" "}
                        </Typography>
                      </Grid>
                    )}
                    {errors.hasOwnProperty("contactEmail") && (
                      <Grid item xs className={classes.columnLeft}>
                        <Typography
                          variant="body2"
                          className={classes.errorMessage}
                        >
                          - Enter a valid email address{" "}
                          <a href="#contactDetails">[Go to contact details]</a>
                        </Typography>
                      </Grid>
                    )}
                    {errors.hasOwnProperty("firstName") && (
                      <Grid item xs className={classes.columnLeft}>
                        <Typography
                          variant="body2"
                          className={classes.errorMessage}
                        >
                          - Enter a valid first name.{" "}
                          <a href="#contactDetails">[Go to contact details]</a>
                        </Typography>
                      </Grid>
                    )}
                    {errors.hasOwnProperty("lastName") && (
                      <Grid item xs className={classes.columnLeft}>
                        <Typography
                          variant="body2"
                          className={classes.errorMessage}
                        >
                          - Enter a valid last name.{" "}
                          <a href="#contactDetails">[Go to contact details]</a>
                        </Typography>
                      </Grid>
                    )}

                    {errors.hasOwnProperty("phoneNumber") && (
                      <Grid item xs className={classes.columnLeft}>
                        <Typography
                          variant="body2"
                          className={classes.errorMessage}
                        >
                          - Enter a valid phone number.{" "}
                          <a href="#contactDetails">[Go to contact details]</a>
                        </Typography>
                      </Grid>
                    )}
                  </Grid>

                  
                  {/* End of a row 
      
      */}
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                  {debug && (
                    <>
                      <pre style={{ textAlign: "left" }}>
                        <strong>Values</strong>
                        <br />
                        {JSON.stringify(values, null, 2)}
                      </pre>
                      <pre style={{ textAlign: "left" }}>
                        <strong>Errors</strong>
                        <br />
                        {JSON.stringify(errors, null, 2)}
                      </pre>

                      <pre style={{ textAlign: "left" }}>
                        <strong>Test</strong>
                        <br />
                        {JSON.stringify(testWebsite(), null, 2)}
                      </pre>

                      
                    </>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </ElementsConsumer>
      )}
      {isPaymentSuccess && <PaymentSuccess />}
    </reactFragment>
  );
}
