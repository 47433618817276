import React from 'react'

import PropTypes from 'prop-types'

import './component.css'

const AppComponent = (props) => {
  return (
    <div className="component-container">
      <div className="component-container1">
        <img
          alt={props.image_alt}
          src={props.image_src}
          loading="lazy"
          className="component-image"
        />
      </div>
      <span className="component-text">{props.Info_title2}</span>
    </div>
  )
}

AppComponent.defaultProps = {
  Info_title2: 'Regular Times',
  image_alt: 'image',
  image_src: '/playground_assets/pocket-watch-200h.png',
}

AppComponent.propTypes = {
  Info_title2: PropTypes.string,
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
}

export default AppComponent
