import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';


const styles = {
    root: {
        maxWidth: 162,
        backgroundColor:  '#0D2C54',
        textTransform: 'none',
        borderRadius: '20px',
        color: '#ffffff',
        height: '34px',

        boxShadow: 'none',

        '&:hover': {
            backgroundColor: '#0D2C54',
      
          },
          '&:active': {
      
            backgroundColor: '#0D2C54',
      
          },
          '&:focus': {
            backgroundColor: '#0D2C54',
          },

    },
};

function classNames(props) {
    const { classes, children, className, ...other } = props;

    return (
        <Button className={clsx(classes.root, className)} {...other}>
            {children || 'class names'}
        </Button>
    );
}

classNames.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
};

export default withStyles(styles)(classNames);