import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';

import PersistentTextfield from '../components/PersistentTextfield';
import {RegistrationDetails} from '../components/registationdetails';

import { theme } from '../ccsc_theme';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        marginTop: "8%",
        marginLeft: "8%",
        marginRight: "8%",
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        marginLeft: "8%",
        marginTop: "4px",
        marginBottom: "4px",
    },
}));

export default function Registration() {
    const classes = useStyles();

    return(
        <React.Fragment>
        <AppBar position="static">
            <Typography variant="h6" className={classes.title}>
                CCSC Registration 2021
            </Typography>
        </AppBar>
        <form className={classes.root} noValidate autoComplete="off">
       <RegistrationDetails.Consumer >   
       {value => (   
            <PersistentTextfield inputValue={value.firstName} />
            )}
      </RegistrationDetails.Consumer>
    </form>
        <div className={classes.root}>
            hello
            </div>
            </React.Fragment>
    );
};
