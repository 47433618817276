import React, { useState } from 'react';
import { makeStyles, createMuiTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core'; 
import fish from "../svg/fish.svg";



const itemFonts = makeStyles((theme) => ({
  normal: {
    fontFamily: ['Karla', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontWeight: "bold",
    [theme.breakpoints.up('md')]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: "14px",
      },
    fontSize: "18px",
    lineHeight: 1.61,
    letterSpacing: "normal",
  },
  questionText: {
  [theme.breakpoints.up('md')]: {
    paddingTop: "0px",
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: "2px",
    },
  },
  fishIcon: {
      stroke: "#0D2C54",
    [theme.breakpoints.up("md")]: {
      width: 20,
      height: 20,
    },
    [theme.breakpoints.down("sm")]: {
      width: 20,
      height: 20,
    },
  },

}));

const defaultProps = {
  m: 0,
  border: 0,
  bgcolor: '#E8E4FC',  //TODO replace with a style
  paddingTop: '40px', 
  paddingBottom: '34px',
  paddingLeft: '25px',
  paddingRight: '15vw',
  marginTop: '10.5px'
  
};

const iconProps = {
  
  style: { width: '14px', height: '13px', margin: '6px 17.1px 0 0', stroke: "#faafff" },
};


export default function FishListItem({item_content}){
  const classes = itemFonts();

  return (
    <div >
      <Box display="flex" direction="row" alignItems="flex-start" style={{ marginTop: '25px' }}>
          <Box direction="column" display="flex" alignItems="flex-start">
          <img src={fish} alt="fish" className={classes.fishIcon} />
          </Box>
          <Box display="flex" direction="row" justify="center"  alignItems="center" style={{marginLeft: "15px"}} >
          <Typography variant="body" className={classes.questionText} >
          <div dangerouslySetInnerHTML={{ __html: item_content }} />

          </Typography>
          </Box>
        </Box> 
    </div>
  )

}