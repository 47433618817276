import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Helmet } from "react-helmet";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    marginTop: "8%",
    marginLeft: "8%",
    marginRight: "8%",
    marginBottom: "8%",
    flexGrow: 1,
  },
  heroRoot: {
    padding: 0,

    marginRight: "8%",

    flexGrow: 1,

    //backgroundColor: "#FDB78B", // Average color of the background image.
    width: `100%`,
    //height: 'calc(40vw)',
    [theme.breakpoints.up("xs")]: {
      height: "100px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "499px",
    },

    margin: 0,
    marginBottom: 0,
  },
  heroImage: {
    position: "absolute",
    top: "50px",
  },
  intro_text: {
    fontFamily: [
      "Droid Serif",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: 400,
    fontSize: "40px",
    fontStyle: "italic",
    lineHeight: "40px",
    [theme.breakpoints.up("md")]: {
    marginBottom: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "60px",
    },
    color: theme.palette.primary.contrastText,
  },
  title_text: {
    fontFamily: [
      "Montserrat",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    fontWeight: 700,
    [theme.breakpoints.up("md")]: {
      fontSize: "75px",
      marginBottom: "50px",
      lineHeight: "75px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      marginBottom: "20px",
      lineHeight: "25px",
    },
    color: theme.palette.primary.contrastText,
  },
  titlecontainer: {
    [theme.breakpoints.up("md")]: {
      marginTop: "300px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "75px",
    },
  },
  bannerImage: {
    backgroundImage: `url('/img/ccsc-background.png')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundColor: "#ffffff", // Average color of the background image.
    width: "100%",
    [theme.breakpoints.up("md")]: {
      height: "833px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "400px",
    },

    margin: 0,
    padding: 0,
  },
  columnLeft: {
    padding: theme.spacing(0),
    paddingRight: "4px",

    flexGrow: 1,
  },

  columnRight: {
    padding: theme.spacing(0),
    paddingLeft: "4px",

    flexGrow: 1,
  },
  coreColumnRow: {
    width: "100%",

    paddingTop: "12px",

    [theme.breakpoints.up("md")]: {
      maxWidth: "450px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "350px",
    },
  },
}));

export default function Registration() {
    const classes = useStyles();
  return (
    <React.Fragment>
      <Helmet>
        {
          //SEO goes here!
        }
        <meta charSet="utf-8" />
        <title>Registration Confirmation</title>
        <meta
          name="description"
          content="A member run swimming club in Comberton, Cambridge UK"
        />
      </Helmet>

 {/* Start of a row */}
 <Grid
                id="contactDetails"
                item
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.coreColumnRow}
              >
                <Grid item xs className={classes.columnLeft}>
                  <Typography align="center" variant="h1">
                    Registration Confirmed
                  </Typography>
                </Grid>
              </Grid>
              {/* End of a row */}

               {/* Start of a row */}
               <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                className={classes.coreColumnRow}
              >
                <Grid item xs className={classes.columnLeft}>
                  <Typography className={classes.explanation}>
                    Thank you for becoming a member of CCSC.  You will receive a confirmation by email. If you do not receive this email or would like to change any of your registration details then 
                    please contact us at: <a href="mailto:contact@comberton-pool.org.uk">contact@comberton-pool.org.uk.</a>
                  </Typography>
                </Grid>
              </Grid>
              {/* End of a row 
      
      */}

    </React.Fragment>
    );
}
