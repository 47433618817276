import React, { useState, useEffect } from "react";
import InputBase from "@material-ui/core/InputBase";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { db, auth } from "../elements/firebaseHelpers";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import calendar_icon from "../svg/calendar_icon.svg";
import { getUserLocale } from "get-user-locale";
import Box from "@material-ui/core/Box";
import PoolIcon from "@material-ui/icons/Pool";

const CustomSelect = withStyles((theme) => ({
  input: {
    borderRadius: 5,
    position: "relative",
    backgroundColor: "#D9E6F7",
    border: "1px solid #0D2C54",
    fontSize: 12,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    "&:focus": {
      borderRadius: 6,
      borderColor: "#80bdff",
      //boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const useStyles_paper = makeStyles({
  root: {
    borderRadius: 6,
    border: "2px solid #0D2C54",
  },
});

const useStyles_tpaper = makeStyles({
  root: {
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,

    borderRadius: 0,
    backgroundColor: "#FFB400",
  },
});

const StyledMenuItemTop = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#D9E6F7",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#0F2D53",
      },
    },
  },
}))(MenuItem);

const StyledMenuItem1 = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#FFF8E5",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#0F2D53",
      },
    },
  },
}))(MenuItem);

const StyledMenuItem2 = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: "#E7EAEE",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#0F2D53",
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) => ({
  root: theme.bodyBanner,
  gridRow: theme.pageMargins,
  boldBody: theme.boldBody,
  column_outer: {
    width: "40%",
    height: "37px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#D9E6F7"
    
  },
  column_inner: {
    width: "20%",
    height: "37px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  column_title: {
    width: "60%",
    height: "37px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  select: {
    color: "#0D2C54",
  },
}));

const defaultProps_outer = {
  bgcolor: "#D9E6F7",
  borderColor: "text.primary",
  style: { width: "40%", height: "37px" },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const defaultProps_inner = {
  bgcolor: "#D9E6F7",
  borderColor: "text.primary",
  style: { width: "20%", height: "37px" },
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default function Session({ SessionDetails, MemberDetails }) {
  const classes = useStyles();
  const paper_class = useStyles_paper();
  const tpaper_class = useStyles_tpaper();
  const [changesMade, setchangesMade] = useState(false);
  const [slot1, setSlot1] = useState({});
  const [slot2, setSlot2] = useState({});
  const [fam_booking, setFam_booking] = useState(["", "", "", "", "", "", ""]);

  const options = { year: "numeric", month: "long", day: "numeric", weekday: 'long' };
  const timeOptions = { hour: "numeric", minute: "numeric" };
  let session_date; //SessionDetails.Date
  let session_starttime,
    session_end,
    session_slot2,
    start_slot1,
    start_slot2,
    end_session;
  session_date = new Date(SessionDetails.Date);
  session_starttime = new Date(SessionDetails.startTime);
  session_slot2 = new Date(SessionDetails.startTime);
  session_slot2.setHours(session_slot2.getHours() + 1);
  session_end = new Date(SessionDetails.startTime);
  session_end.setHours(session_end.getHours() + 2);

  start_slot1 = session_starttime.toLocaleTimeString(
    getUserLocale(),
    timeOptions
  );
  start_slot2 = session_slot2.toLocaleTimeString(getUserLocale(), timeOptions);
  end_session = session_end.toLocaleTimeString(getUserLocale(), timeOptions);

  //is this session still bookable? Up to one hour. if not only show the booking but unchangeable

  //create slot ids ( in an array)

  function countTrue(objectTocount) {
    let counter = 0;
    if (objectTocount.fam1) counter++;
    if (objectTocount.fam2) counter++;
    if (objectTocount.fam3) counter++;
    if (objectTocount.fam4) counter++;
    if (objectTocount.fam5) counter++;
    if (objectTocount.fam6) counter++;

    return counter;
  }

  function updateBookings(event) {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        let newBooking = [...fam_booking];

        var sessionStatus = db.ref(
          "/bookingslots/" +
            SessionDetails.Date.substr(0, 10) +
            SessionDetails.startTime.substr(11, 2) +
            "/"
        );

        sessionStatus.transaction((sessions) => {
          if (sessions) {
            if (event.target.value !== "") {
              if (event.target.value.substr(8, 2) === "mp") {
                if (event.target.value.substr(6, 1) === "1")
                  sessions.slot1.mini_pool--;
                else sessions.slot2.mini_pool--;
              } else {
                if (event.target.value.substr(6, 1) === "1")
                  sessions.slot1.lanes--;
                else sessions.slot2.lanes--;
              }
            }

            if (
              newBooking[parseInt(event.target.name.substr(3, 1) - 1)].substr(
                8,
                2
              ) === "mp"
            ) {
              if (
                newBooking[parseInt(event.target.name.substr(3, 1) - 1)].substr(
                  6,
                  1
                ) === "1"
              )
                sessions.slot1.mini_pool++;
              else sessions.slot2.mini_pool++;
            }
            if (
              newBooking[parseInt(event.target.name.substr(3, 1) - 1)].substr(
                8,
                2
              ) === "la"
            ) {
              if (
                newBooking[parseInt(event.target.name.substr(3, 1) - 1)].substr(
                  6,
                  1
                ) === "1"
              )
                sessions.slot1.lanes++;
              else sessions.slot2.lanes++;
            }
          }
          return sessions;
        });

        var bookingStatus = db.ref("/bookingen/");

        var tempRecord = {};

        var updates = {};
        newBooking[parseInt(event.target.name.substr(3, 1) - 1)] =
          event.target.value;

        if (newBooking[0])
          tempRecord = { ...tempRecord, ...{ fam1: newBooking[0] } };
        if (newBooking[1])
          tempRecord = { ...tempRecord, ...{ fam2: newBooking[1] } };
        if (newBooking[2])
          tempRecord = { ...tempRecord, ...{ fam2: newBooking[2] } };
        if (newBooking[3])
          tempRecord = { ...tempRecord, ...{ fam2: newBooking[3] } };
        if (newBooking[4])
          tempRecord = { ...tempRecord, ...{ fam2: newBooking[4] } };
        if (newBooking[5])
          tempRecord = { ...tempRecord, ...{ fam2: newBooking[5] } };

        updates[
          MemberDetails.tag_id +
            "/" +
            SessionDetails.Date.substr(0, 10) +
            SessionDetails.startTime.substr(11, 2)
        ] = {
          ...tempRecord,
        };

        bookingStatus.update(updates);

        setFam_booking(newBooking); //should be done last
      }
    });
    //var lanes = calculateLanes(); //return an object that can be added/updated
    //var mini_pools = calculateMinipools(checked1_mp);
  }

  useEffect(() => {
    async function fetchData() {
      auth.onAuthStateChanged(function (user) {
        if (user) {
          //retrieve availabilities
          var sessionStatus = db.ref(
            "/bookingslots/" +
              SessionDetails.Date.substr(0, 10) +
              SessionDetails.startTime.substr(11, 2) +
              "/"
          );

          //keep updating if the value changes
          sessionStatus.on("value", (snapshot) => {
            setSlot1({
              mini_pool: snapshot.val().slot1.mini_pool,
              lanes: snapshot.val().slot1.lanes,
            });
            setSlot2({
              mini_pool: snapshot.val().slot2.mini_pool,
              lanes: snapshot.val().slot2.lanes,
            });
          });

          //retrieve member specific data
          var bookingStatus = db.ref(
            "/bookingen/" +
              MemberDetails.tag_id +
              "/" +
              SessionDetails.Date.substr(0, 10) +
              SessionDetails.startTime.substr(11, 2)
          );

          //if a previous booking exists then load the details for this
          if (MemberDetails.tag_id && SessionDetails) {
            console.log(
              "/bookingen/" +
                MemberDetails.tag_id +
                "/" +
                SessionDetails.Date.substr(0, 10) +
                SessionDetails.startTime.substr(11, 2)
            );
            bookingStatus.once("value", (snapshot) => {
              if (snapshot.val()) {
                //console.log(snapshot.val().checked1_lanes);
                //Set the values for the member sessions

                let temp_booking = [];

                if (snapshot.val().fam1) temp_booking[0] = snapshot.val().fam1;
                else temp_booking[0] = "";
                if (snapshot.val().fam2) temp_booking[1] = snapshot.val().fam2;
                else temp_booking[1] = "";
                if (snapshot.val().fam3) temp_booking[2] = snapshot.val().fam1;
                else temp_booking[2] = "";
                if (snapshot.val().fam4) temp_booking[3] = snapshot.val().fam2;
                else temp_booking[3] = "";
                if (snapshot.val().fam5) temp_booking[4] = snapshot.val().fam1;
                else temp_booking[4] = "";
                if (snapshot.val().fam6) temp_booking[5] = snapshot.val().fam2;
                else temp_booking[5] = "";

                setFam_booking(temp_booking);
              }
            });
          }
        }
      });
    }

    fetchData();
  }, [MemberDetails]);

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        style={{ marginBottom: 39 }}
        variant="outlined"
        classes={{
          root: paper_class.root, // class name, e.g. `classes-nesting-root-x`
        }}
      >
        <Grid container direction="column">
          <Grid item container alignItems="center" direction="row">
            <Paper
              style={{
                width: "100%",
                flexGrow: 1,
                display: "flex",
                height: 68,
              }}
              classes={{
                root: tpaper_class.root, // class name, e.g. `classes-nesting-root-x`
              }}
              variant="outlined"
            >
              <img
                src={calendar_icon}
                alt="Example diagram"
                width="30px"
                height="30px"
                style={{ marginLeft: 10, marginTop: 20 }}
              />

              <Typography
                variant="body"
                style={{ marginLeft: 10, marginTop: 26 }}
              >
                Date
              </Typography>

              <Typography
                variant="body"
                style={{ marginLeft: 60, marginTop: 26 }}
                className={classes.boldBody}
              >
                {session_date.toLocaleDateString(getUserLocale(), options)}
              </Typography>
            </Paper>
          </Grid>

          <Grid item>
            <Box style={{backgroundColor:"#D9E6F7", padding: 10, marginTop: 10, marginLeft: 10, marginRight: 10}} >
            <Box display="flex" style={{justifyContent: "center"}}  >

                <Typography
                  variant="body"
                  align="center"
                  className={classes.boldBody}
                >
                  Availability
                </Typography>
            </Box>
            <Box display="flex" >
              <Box className={classes.column_outer}>
                <Typography
                  variant="body"
                  align="center"
                  className={classes.boldBody}
                >
                  Time
                </Typography>
              </Box>
              <Box className={classes.column_inner}>
                <Typography variant="body" align="center">
                  Lanes
                </Typography>
              </Box>
              <Box className={classes.column_outer}>
                <Typography variant="body" align="center">
                  Mini pools
                </Typography>
              </Box>
            </Box>
            <Box display="flex" >
              <Box border={0} borderRight={1} style={{ paddingLeft: 10 }} {...defaultProps_outer}  >
                <Typography variant="body" align="center">
                  {start_slot1}-{start_slot2}
                </Typography>
              </Box>
              <Box border={0} borderRight={1}  {...defaultProps_inner}>
                {slot1.lanes < 1 ? (
                  <Typography variant="body" align="center bg" color="error">
                    Full
                  </Typography>
                ) : (
                  <Typography variant="body" align="center bg">
                    {slot1.lanes}
                  </Typography>
                )}
              </Box>
              <Box style={{ paddingRight: 10 }} {...defaultProps_outer}>
                <Typography variant="body" align="center">
                  {slot1.mini_pool < 1 ? (
                    <Typography variant="body" align="center bg" color="error">
                      Full
                    </Typography>
                  ) : (
                    <Typography variant="body" align="center bg">
                      {slot1.mini_pool}
                    </Typography>
                  )}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" >
              <Box
                border={0}
                borderRight={1}
                borderTop={1}
                style={{ paddingLeft: 10 }}
                {...defaultProps_outer}
              >
                <Typography variant="body">
                  {start_slot2}-{end_session}
                </Typography>
              </Box>
              <Box
                border={0}
                borderRight={1}
                borderTop={1}
                style={{ paddingRight: 10 }}
                {...defaultProps_inner}
              >
                <Typography variant="body">
                  {" "}
                  {slot2.lanes < 1 ? (
                    <Typography variant="body" align="center bg" color="error">
                      Full
                    </Typography>
                  ) : (
                    <Typography variant="body" align="center bg">
                      {slot2.lanes}
                    </Typography>
                  )}
                </Typography>
              </Box>
              <Box border={0} borderTop={1} style={{ paddingLeft: 10 }} {...defaultProps_outer}>
                <Typography variant="body">
                  {" "}
                  {slot2.mini_pool < 1 ? (
                    <Typography variant="body" align="center bg" color="error">
                      Full
                    </Typography>
                  ) : (
                    <Typography variant="body" align="center bg">
                      {slot2.mini_pool}
                    </Typography>
                  )}
                </Typography>
              </Box>
            </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          
        >
          <Grid item style={{ marginTop: 10 , marginBottom: 10 , marginLeft: 10 }}>

            <PoolIcon style={{ marginRight: 10 }} />
            <Typography variant="body">{MemberDetails.Name}</Typography>

          </Grid>
          <Grid item style={{ marginTop: 10 , marginBottom: 10 , marginRight: 10 }}>
            {MemberDetails.Name && (
              <FormControl>
                <Select
                  variant="outlined"
                  name="fam1"
                  value={fam_booking[0]}
                  onChange={updateBookings}
                  displayEmpty
                  className={classes.select}
                  inputProps={{ "aria-label": "Select a slot" }}
                  input={<CustomSelect />}
                >
                  <StyledMenuItemTop value="">
                    <em>Not swimming</em>
                  </StyledMenuItemTop>
                  <StyledMenuItem1
                    value={"fam1_s1_la"}
                    disabled={slot1.lanes < 1}
                  >
                    {start_slot1}-{start_slot2} Lane
                  </StyledMenuItem1>
                  <StyledMenuItem2
                    value={"fam1_s1_mp"}
                    disabled={slot1.mini_pool < 1}
                  >
                    {start_slot1}-{start_slot2} Mini pool
                  </StyledMenuItem2>
                  <StyledMenuItem1
                    value={"fam1_s2_la"}
                    disabled={slot2.lanes < 1}
                  >
                    {start_slot2}-{end_session} Lane
                  </StyledMenuItem1>
                  <StyledMenuItem2
                    value={"fam1_s2_mp"}
                    disabled={slot2.mini_pool < 1}
                  >
                    {start_slot2}-{end_session} Mini pool
                  </StyledMenuItem2>
                </Select>
              </FormControl>
            )}
          </Grid>
        </Grid>

        {MemberDetails.Fam2 && (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"

          >
            <Grid item style={{ marginBottom: 10 , marginLeft: 10 }}>
              <PoolIcon style={{ marginRight: 10 }} />
              <Typography variant="body">{MemberDetails.Fam2}</Typography>
            </Grid>
            <Grid item style={{ marginBottom: 10 , marginRight: 10 }}>
              <FormControl>
                <Select
                  variant="outlined"
                  name="fam2"
                  value={fam_booking[1]}
                  onChange={updateBookings}
                  displayEmpty
                  className={classes.select}
                  inputProps={{ "aria-label": "Select a slot" }}
                  input={<CustomSelect />}
                >
                  <StyledMenuItemTop value="">
                    <em>Not swimming</em>
                  </StyledMenuItemTop>
                  <StyledMenuItem1
                    value={"fam2_s1_la"}
                    disabled={slot1.lanes < 1}
                  >
                    {start_slot1}-{start_slot2} Lane
                  </StyledMenuItem1>
                  <StyledMenuItem2
                    value={"fam2_s1_mp"}
                    disabled={slot1.mini_pool < 1}
                  >
                    {start_slot1}-{start_slot2} Mini pool
                  </StyledMenuItem2>
                  <StyledMenuItem1
                    value={"fam2_s2_la"}
                    disabled={slot2.lanes < 1}
                  >
                    {start_slot2}-{end_session} Lane
                  </StyledMenuItem1>
                  <StyledMenuItem2
                    value={"fam2_s2_mp"}
                    disabled={slot2.mini_pool < 1}
                  >
                    {start_slot2}-{end_session} Mini pool
                  </StyledMenuItem2>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}

        {MemberDetails.Fam3 && (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item style={{ marginBottom: 10 , marginLeft: 10 }}>
              <PoolIcon style={{ marginRight: 10 }} />
              <Typography variant="body">{MemberDetails.Fam3}</Typography>
            </Grid>
            <Grid item style={{ marginBottom: 10 , marginRight: 10 }}>
              <FormControl>
                <Select
                  variant="outlined"
                  name="fam3"
                  value={fam_booking[2]}
                  onChange={updateBookings}
                  displayEmpty
                  className={classes.select}
                  inputProps={{ "aria-label": "Select a slot" }}
                  input={<CustomSelect />}
                >
                  <StyledMenuItemTop value="">
                    <em>Not swimming</em>
                  </StyledMenuItemTop>
                  <StyledMenuItem1
                    value={"fam3_s1_la"}
                    disabled={slot1.lanes < 1}
                  >
                    {start_slot1}-{start_slot2} Lane
                  </StyledMenuItem1>
                  <StyledMenuItem2
                    value={"fam3_s1_mp"}
                    disabled={slot1.mini_pool < 1}
                  >
                    {start_slot1}-{start_slot2} Mini pool
                  </StyledMenuItem2>
                  <StyledMenuItem1
                    value={"fam3_s2_la"}
                    disabled={slot2.lanes < 1}
                  >
                    {start_slot2}-{end_session} Lane
                  </StyledMenuItem1>
                  <StyledMenuItem2
                    value={"fam3_s2_mp"}
                    disabled={slot2.mini_pool < 1}
                  >
                    {start_slot2}-{end_session} Mini pool
                  </StyledMenuItem2>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}

        {MemberDetails.Fam4 && (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item style={{ marginBottom: 10 , marginLeft: 10 }}>
              <PoolIcon style={{ marginRight: 10 }} />
              <Typography variant="body">{MemberDetails.Fam4}</Typography>
            </Grid>
            <Grid item style={{ marginBottom: 10 , marginRight: 10 }}>
              <FormControl>
                <Select
                  variant="outlined"
                  name="fam4"
                  value={fam_booking[3]}
                  onChange={updateBookings}
                  displayEmpty
                  className={classes.select}
                  inputProps={{ "aria-label": "Select a slot" }}
                  input={<CustomSelect />}
                >
                  <StyledMenuItemTop value="">
                    <em>Not swimming</em>
                  </StyledMenuItemTop>
                  <StyledMenuItem1
                    value={"fam4_s1_la"}
                    disabled={slot1.lanes < 1}
                  >
                    {start_slot1}-{start_slot2} Lane
                  </StyledMenuItem1>
                  <StyledMenuItem2
                    value={"fam4_s1_mp"}
                    disabled={slot1.mini_pool < 1}
                  >
                    {start_slot1}-{start_slot2} Mini pool
                  </StyledMenuItem2>
                  <StyledMenuItem1
                    value={"fam4_s2_la"}
                    disabled={slot2.lanes < 1}
                  >
                    {start_slot2}-{end_session} Lane
                  </StyledMenuItem1>
                  <StyledMenuItem2
                    value={"fam4_s2_mp"}
                    disabled={slot2.mini_pool < 1}
                  >
                    {start_slot2}-{end_session} Mini pool
                  </StyledMenuItem2>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
      </Paper>
    </React.Fragment>
  );
}
