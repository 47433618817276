import React from "react";
import LoginOut from "./components/login_out";
//import Home from './pages/Home';
import Home from './views/home';
import Booking from './pages/Booking';
import Registration from './pages/registration';
import Signup from './pages/Signup';
import Signup2 from './pages/Signup2';
import PaymentSuccess from './pages/PaymentSuccess';

const routes = [

   { name: "Home", path: "/", exact: true, main: () => <Home /> },
 //  { name: "Login", path: "/login", exact: true, main: () => <LoginOut /> },
   { name: "Registration", path: "/register", exact: true, main: () => <Signup2 /> },
  // { name: "Booking", path: "/booking", exact: true, main: () => <Booking /> },
   { name: "success", path: "/success", exact: true, main: () => <PaymentSuccess /> },
   { name: "Signup2", path: "/signup2", exact: true, main: () => <Signup2 /> }
  ];

export default routes;

