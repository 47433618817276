import firebase from 'firebase/app';
import 'firebase/database';   // for cloud realtime database
import 'firebase/analytics';   // for analystics
import 'firebase/auth';  
import 'firebase/functions';  
import '@react-firebase/auth';
import { testWebsite } from "../functions/testFunctions";

import firebaseConfig from "../firebase.config";
firebase.initializeApp(firebaseConfig);

export var db = firebase.database();
export const analytics = notestAnalytics();
export const CCCSFunctions = firebase.functions();

//https://blog.logrocket.com/user-authentication-firebase-react-apps/
export var auth = firebase.auth();

//the function below ensures that we are not getting any false positives when running the app locally for development
function notestAnalytics(){
    if (testWebsite() === true) {
        return null;
    }else{
        return firebase.analytics();
    }
}