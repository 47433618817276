import React from 'react'

import { Helmet } from 'react-helmet'

import AppComponent from '../components/component'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>CCSC v4</title>
        <meta property="og:title" content="CCSC v4" />
      </Helmet>
      <div className="home-header">
        <span className="home-text">CCSC</span>
      </div>
      <div className="home-container01">
        <div className="home-container02">
          <div className="home-container03">
            <span className="home-text01">
              <span>Comberton Community</span>
              <br></br>
              <span>Swimming Club</span>
            </span>
            <span className="home-text05">A pool for and run by members</span>
            <a
              href="https://comberton-pool.org.uk/register"
              Color="Black"
              className="home-button"
            >
              Become a member
            </a>
            <a
              href="https://comberton-pool.org.uk/register"
              className="home-button2 Button2"
            >
              Become a member
            </a>
          </div>
        </div>
      </div>
      <div className="home-container04">
        <div className="home-container05">
          <span className="home-text06">Season 2024</span>
          <span className="home-text07">
            <span>11 May - 7 September*</span>
            <br></br>
          </span>
        </div>
      </div>
      <div className="home-container06">
        <div className="home-container07"></div>
        <div className="home-container08">
          <span className="home-text10">
            *We may extend the season by a few weeks if the weather allows and
            we have enough members who are prepared to do additional duties.
          </span>
        </div>
      </div>
      <div className="home-container09">
        <div className="home-container10">
          <AppComponent></AppComponent>
          <span className="home-infobody">
            <span>
              Monday, Tuesday*, Wednesday &amp; Friday
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text12">6:30-8:30pm</span>
            <span>
              .
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>
              Saturday &amp; Sunday
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text16">3-5pm.</span>
            <br></br>
            <span>*Tuesdays are for lane-swimming only.</span>
          </span>
        </div>
        <div className="home-container11">
          <AppComponent
            image_src="/playground_assets/owl-200h.png"
            Info_title2="School Holidays"
          ></AppComponent>
          <span className="home-infobody1">
            <span>
              During the school holidays we run two extra sessions on
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br></br>
            <span>
              Tuesday &amp; Thursday
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text22">2:30-4:30pm.</span>
          </span>
        </div>
        <div className="home-container12">
          <AppComponent
            image_src="/playground_assets/sunset-200h.png"
            Info_title2="Daylight Adjustments"
          ></AppComponent>
          <span className="home-infobody2">
            <span>
              Later in the year, when it gets darker in the evenings, we change
              the opening times to
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span className="home-text24">6-7:30pm.</span>
          </span>
        </div>
      </div>
      <div className="home-container13"></div>
      <div className="home-container14">
        <div className="home-who-can-become-a-member">
          <img
            alt="image"
            src="/playground_assets/li_user-check-200h.png"
            className="home-image"
          />
          <div className="home-container15">
            <span className="home-text25">Who can become a member?</span>
            <span className="home-text26">
              Anybody who lives close enough to the pool to make good use of it,
              as well as contribute to the running of the club by helping as a
              safety officer or an administrator during the opening hours.
            </span>
          </div>
        </div>
        <div className="home-membership-types">
          <img
            alt="image"
            src="/playground_assets/li_pound-sterling-200h.png"
            className="home-image1"
          />
          <div className="home-container16">
            <span className="home-text27">Membership Types &amp; Fees</span>
            <span className="home-text28">
              <span className="home-text29">Individual Membership</span>
              <br className="home-text30"></br>
              <span>
                Membership for only one person. Yearly fee: £55. 
              </span>
              <br></br>
              <br></br>
              <span className="home-text34">Family Membership</span>
              <br></br>
              <span>
                Membership for up to 6 people of a household who live at the
                same address. Yearly fee: £70.
              </span>
            </span>
          </div>
        </div>
        <div className="home-container17">
          <img
            alt="image"
            src="/playground_assets/li_settings-200h.png"
            className="home-image2"
          />
          <div className="home-container18">
            <span className="home-text37">Member Duties</span>
            <span className="home-text38">
              Members are expected to &apos;do their bit&apos; to keep the club
              running for everyone. There are 2 types of duties that members can
              choose to take on.
            </span>
            <div className="home-container19">
              <div className="home-container20">
                <span className="home-text39">
                  <span className="home-text40">Safety Officer</span>
                  <br></br>
                  <span>
                    To safely run the pool we need 2 safety officers on the pool
                    side. Members who choose to contribute as a safety officer
                    will receive the appropriate training to act as a first or
                    second safety officer. First safety officers will also
                    receive RLSS life support training and will need to
                    successfully complete an exam every 2 years.
                  </span>
                  <br></br>
                  <br></br>
                  <span>
                    If you are a competent swimmer then please consider
                    volunteering as a safety officer.
                  </span>
                  <br></br>
                  <br></br>
                  <span className="home-text48">1st Safety officers</span>
                  <br></br>
                  <span>
                    The 2024 training course for 1st safety officers will run on
                    the 13 and 14 of April (2 day course). Only those 1st
                    Safety Officers who did not complete the course in 2023 have
                    to attend this course.
                  </span>
                  <br></br>
                  <br></br>
                  <span className="home-text53">2nd Safety officers</span>
                  <br></br>
                  <span>
                    2nd Safety officers need to attend one of our training
                    sessions on Saturday 22nd of April or Sunday 5th of May.
                    You will also need to attend a theory session on Tuesday
                    16th or 23rd of April 19:30-21:30 at Toft Village Hall.
                  </span>
                  <br></br>
                  <br></br>
                </span>
              </div>
              <div className="home-container21">
                <span className="home-text58">
                  <span className="home-text59">Administrator</span>
                  <br></br>
                  <span>
                    Administrators help with the running of the club by manning
                    our admin hut to administer pool entrants and do general
                    support tasks during opening hours. No formal training is
                    needed but you will require up-to-date knowledge of the club
                    rules (which will be provided).
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-container22"></div>
    </div>
  )
}

export default Home
