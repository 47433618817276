import "./App.css";
import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { theme } from "./ccsc_theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import routes from "./routes.js";
import FourzeroFour from "./pages/FourzeroFour";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClock, faCalendarAlt} from '@fortawesome/free-regular-svg-icons';  //https://fontawesome.com/icons?d=listing&p=2
import { faPlane, faQuestion, faPoundSign, faLifeRing } from '@fortawesome/free-solid-svg-icons';  //https://fontawesome.com/icons?d=listing&p=2
import firebaseConfig from "./firebase.config";
import firebase from "firebase/app";
import "firebase/auth";
import {
  FirebaseAuthProvider,
} from "@react-firebase/auth";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Home from './views/home';


import { testWebsite } from "./functions/testFunctions";

var stripePubkey;

if (testWebsite()){
  stripePubkey="pk_test_NppkoPzhPygVaJ2P1wpJpTLa";
}else{
  stripePubkey="pk_live_oXXavNB3oC9aVXRAYYfEzf9m";
  //stripePubkey="pk_test_NppkoPzhPygVaJ2P1wpJpTLa";
};


const stripe = loadStripe(stripePubkey);

library.add(faClock, faPlane, faCalendarAlt, faQuestion, faPoundSign, faLifeRing  ); 

// ...

function App() {
  return (
    <Elements stripe={stripe}>
      <FirebaseAuthProvider firebase={firebase} {...firebaseConfig}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <div >
          <Router>
          <Switch>
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact}
                    component={route.main} 
                  />
                ))}
                <Route component={() => <Home />} status={404} />
              </Switch>
          </Router>
          </div>

      </ThemeProvider>
      </FirebaseAuthProvider>
    </Elements>
  );
}

export default App;
