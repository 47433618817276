import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";

import { theme } from "../ccsc_theme";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    marginTop: "8%",
    marginLeft: "8%",
    marginRight: "8%",
    flexGrow: 1,
  },
}));

export default function Home() {
  return <React.Fragment></React.Fragment>;
}