import { createMuiTheme } from '@material-ui/core/styles';

const defaultTheme = createMuiTheme();

export const theme = createMuiTheme({
  palette: {
    secondary: {
      light: '#FFC640',
      main: '#FFB400',
      dark: '#BF8600',
      contrastText: '#0D2C54',
    },
    primary: {
      light: '#1B5AAD',
      main: '#0D2C54',
      dark: '#0A213F',
      contrastText: '#FCFDFD',
    },
    text: {
        primary: '#0D2C54',
        secondary: '#0D2C54',
        disabled: '#2272DA',
        hint: '#27233A',
    },
    background: {
        paper: "#FFFFFF",
        default: "#ffffff",
    },
  },
  bodyBanner: {
    [defaultTheme.breakpoints.up('md')]: {
    marginTop: "50px",
    marginBottom: "50px",
    },
    [defaultTheme.breakpoints.down('sm')]: {
      marginTop: "38px",
      marginBottom: "38px",
    },
  },
  pageMargins: {
    marginLeft: "8%",
    marginRight: "8%",
    [defaultTheme.breakpoints.up('md')]: {
    marginTop: "25px",
    marginBottom: "25px",
    },
    [defaultTheme.breakpoints.down('sm')]: {
      marginTop: "18px",
      marginBottom: "18px",
    }
  },

  boldBody: {
    fontFamily: ['Roboto Slab', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontWeight: 700,
    [defaultTheme.breakpoints.up('md')]: {
    fontSize: "14px",
    lineHeight: 1.3,
    },
    [defaultTheme.breakpoints.down('sm')]: {
      fontSize: "14px",
      lineHeight: 1.3,
    },
  },




  htmlFontSize: 8,
  fontFamily: ['Karla', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  fontSize: 14,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  typography: {
    h1: {
      fontFamily: ['Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontWeight: 700,
      [defaultTheme.breakpoints.up('md')]: {
      fontSize: "30px",
      lineHeight: 1.2,
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: "24px",
        lineHeight: 1,
      },
    },
    h2: {
      fontFamily: ['Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontWeight: 700,
      [defaultTheme.breakpoints.up('md')]: {
      fontSize: "40px",
      lineHeight: 1.2,
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: "30px",
        lineHeight: 1,
      },
    },
    h3: {
      fontFamily: ['Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontWeight: 700,
      [defaultTheme.breakpoints.up('md')]: {
      fontSize: "1.5rem",
      lineHeight: 1.2,
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: "1.15rem",
        lineHeight: 1,
      },
    },
    body: {
      fontFamily: ['Roboto Slab', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontWeight: 400,
      [defaultTheme.breakpoints.up('md')]: {
      fontSize: "14px",
      lineHeight: 1.3,
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: "14px",
        lineHeight: 1.3,
      },
    },
    body2: {
      fontFamily: ['Roboto Slab', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontWeight: 400,
      [defaultTheme.breakpoints.up('md')]: {
      fontSize: "14px",
      lineHeight: 1.3,
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: "12px",
        lineHeight: 1.3,
      },
    },
    h4: {
      fontFamily: ['Roboto Slab', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontWeight: 600,
      [defaultTheme.breakpoints.up('md')]: {
      fontSize: "16px",
      lineHeight: 1.3,
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: "14px",
        lineHeight: 1.3,
      },
    }
  },
});

